import { createTheme } from "@mui/material/styles";

const darkTheme = {
  background: "#121212", // fundo preto suave
  reverseBackground: "#E0E0E0",
  text: "#E0E0E0", // texto principal (cinza claro)
  secondaryText: "#B0B0B0", // texto secundário (cinza médio claro)
  button: "#1A73E8", // verde para botões e links
  border: "#333333", // bordas e sombreamento (cinza escuro)
  header: "#FFFFFF", // cabeçalhos (branco)
  secondaryBackground: "#1E1E1E", // fundo secundário (cinza escuro)
  error: "#EF5350", // erro (vermelho claro)
  success: "#66BB6A", // sucesso (verde suave)
};

export const DarkTheme = createTheme({
  ...darkTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#f48fb1",
    },
  },
});
