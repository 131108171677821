import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "_metronic/i18n/language";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import { CampaignService, QuestionService, QuizService } from "app/services";
import { CampaignType, QuizType, RelatedQuizType } from "app/types";
import { schema } from "../quiznew-schema";
import useQuizMessages from "./useQuizFormMessages";
import { QUIZ_TYPES_CONTANTS } from "../../../../../types/quiz-constants";

type QuizFormModelProps = {
  campaignService: CampaignService;
  quizService: QuizService;
  questionService: QuestionService;
};

const {
  ANAMNESE,
  CHILDHOOD_IDENTIFICATION,
  COGNITIVE,
  IDENTIFICATION,
  DISCRIMINATION,
  RATINGS
} = QUIZ_TYPES_CONTANTS;

const useQuizFormModel = ({
  campaignService,
  quizService,
  questionService
}: QuizFormModelProps) => {
  const { id } = useParams<{ id: string }>();
  const locale = useSelector((state: any) => state.i18n.lang);
  const translate = useTranslation();
  const { isDarkMode } = useThemeApp();
  const MESSAGES = useQuizMessages();

  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [quizData, setQuizData] = useState<QuizType | null>(null);
  const [questions, setQuestions] = useState<any[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any | null>(null);
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [campaign, setCampaign] = useState<CampaignType>({} as CampaignType);
  const [userIds, setUserIds] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm<QuizType>({
    resolver: yupResolver(schema),
    defaultValues: {
      quizId: 0,
      campaignId: 0,
      name: "",
      language: locale,
      publishedAt: new Date().toISOString(),
      type: "",
      maxShots: 0,
      userId: "",
      anamneseId: 0,
      campaign: {} as CampaignType,
      mandatoryAnamnese: false,
      relatedByQuiz: {} as RelatedQuizType,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
  });

  useEffect(() => {
    if (id) {
      getQuizById(Number(id));
    }
    getCampaigns();
  }, [id]);

  useEffect(() => {
    setLanguage(locale);
  }, [locale]);

  const getQuizById = async (quizId: number) => {
    try {
      setLoading(true);
      const quiz = await quizService.getById(quizId);
      setCampaign(quiz.campaign);
      setValue("name", quiz.name);
      setValue("campaignId", quiz.campaignId);
      setLanguage(quiz.language);
      setValue("publishedAt", quiz.publishedAt);
      setValue("validatedAt", quiz.validatedAt);
      setValue("type", quiz.type);
      setValue("maxShots", quiz.maxShots);
      setUserIds(quiz.userId);
      setValue("anamneseId", quiz.anamneseId);
      setValue("mandatoryAnamnese", quiz.mandatoryAnamnese);
      setValue("screeningId", quiz.screeningId);
      setQuestions(quiz.questions || []);
      setSelectedType(Number(quiz.type) || 0)
      setQuizData(quiz);
    } catch (error) {
      console.error("Erro ao buscar o quiz:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCampaigns = async () => {
    const data = await campaignService.getCampaigns();
    try {
      setCampaigns(data || []);
    } catch (error) {
      console.error("Erro ao buscar campanhas:", error);
      setCampaigns([]);
    }
  };

  const onSubmit = async (data: QuizType) => {
    setLoading(true);
    try {
      const prepareData: QuizType = {
        ...data,
        userId: userIds,
        language
      };
      let response: QuizType;
      if (id) {
        response = await quizService.update(id, prepareData);
      } else {
        response = await quizService.create(prepareData);
        await getQuizById(response.quizId);
        setOpenQuestionModal(true);
      }

      MESSAGES.getSuccessCreated();
    } catch (error) {
      MESSAGES.getErrorCreated();
    } finally {
      setLoading(false);
    }
  };

  const onEdit = (question: any) => {
    setSelectedQuestion(question);
    setOpenQuestionModal(true);
  };

  const onCleanQuestion = () => {
    setSelectedQuestion(null);
    setOpenQuestionModal(false);
  };

  const onDelete = async (quizQuestionId: number) => {
    const result = await Swal.fire({
      title: translate("deleteRegister_title"),
      text: translate("deleteRegister_message"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translate("buttons.confirm"),
      cancelButtonText: translate("buttons.cancel")
    });

    if (result.isConfirmed) {
      try {
        setLoading(true);
        await questionService.delete(quizQuestionId);
        await getQuizById(Number(id) || quizData?.quizId || watch("quizId"));
        MESSAGES.getSuccessDeleted();
      } catch (error) {
        MESSAGES.getErrorDeleted();
      }
    }
  };

  const handleChangeType = (type: number) => {
    setSelectedType(type);
  };

  return {
    onSubmitForm: handleSubmit(onSubmit),
    styleInput: {
      backgroundColor: isDarkMode ? "transparent" : "#fff",
      color: isDarkMode ? "#ffffff" : "#747a91"
    },
    selectTheTypeQuiz: [
      {
        key: IDENTIFICATION,
        name: translate("screens.quiz.labels.identification")
      },
      { key: ANAMNESE, name: translate("labels_testType_two") },
      {
        key: DISCRIMINATION,
        name: translate("screens.quiz.labels.discrimination")
      },
      { key: RATINGS, name: translate("labels_ratings") },
      {
        key: CHILDHOOD_IDENTIFICATION,
        name: translate("screens_quiz_labels_identificationKid")
      },
      { key: COGNITIVE, name: translate("labels_cognitive") }
    ],
    loading,
    translate,
    register,
    errors,
    quizData,
    watch,
    id,
    onCleanQuestion,
    getQuizById,
    selectedQuestion,
    openQuestionModal,
    setOpenQuestionModal,
    campaign,
    onDelete,
    onEdit,
    questions,
    setUserIds,
    userIds,
    language,
    setLanguage,
    campaigns,
    control,
    selectedType,
    handleChangeType
  };
};

export default useQuizFormModel;
