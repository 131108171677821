import { createTheme } from "@mui/material/styles";

const lightTheme = {
  background: "#FFFFFF", // fundo branco
  reverseBackground: "#333333",
  text: "#333333", // texto principal (cinza escuro)
  secondaryText: "#666666", // texto secundário (cinza médio)
  button: "#1A73E8", // azul para botões e links
  border: "#DDDDDD", // bordas e sombreamento (cinza claro)
  header: "#222222", // cabeçalhos (quase preto)
  secondaryBackground: "#F4F4F4", // fundo secundário (cinza claro)
  error: "#E57373", // erro (vermelho suave)
  success: "#81C784", // sucesso (verde suave)
};
export const LightTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: "light",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#f48fb1",
    },
  },
});
