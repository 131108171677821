import React, { StrictMode } from "react";
import {
  TableCell,
  TableRow,
  Button,
  Stack,
  Typography
} from "@mui/material";
import moment from "moment";
import { Card, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import "react-sortable-tree/style.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";

import withFormValidation from "../../../../hoc/withFormValidation";
import CrudActions from "../../../../partials/datatable/CrudActions";
import { QuestionApiServiceImpl } from "../../../../services/Ananse/impl/QuestionApiService";
import { QuizApiServiceImpl } from "../../../../services/Ananse/impl/QuizApiService";
import IsValidName from "../../../../utils/validators/IsValidName";
import SelectSearchComponent from "../../../auth/Components/Select2";
import Loading from "../../../home/components/Loading";
import ConfirmDeleteDialog from "../../../../partials/datatable/ConfirmDeleteDialog";
import SelectLanguage from "../../components/SelectLanguage";
import { TransferList } from "components";
import TableQuestions from "./component/TableQuestions";
import TableAnswers from "./component/TableAnswers";
import ButtonsQuiz from "./component/ButtonsQuiz";
import { TranslationContext } from "_metronic/i18n/language";
import { Wrapper } from "../Users/components/Wrapper";
import { FormInput } from "../Users/components/FormInput";

const initialQuestion = {
  quizQuestionId: null,
  questionId: null,
  quest: "",
  answers: [],
  fragranceId: null,
  rightAnswer: "",
  type: "slider"
};

const initialAnswer = {
  quizQuestionAnswerId: null,
  answerId: null,
  desc: "",
  description: "",
  isAnswerFinish: false,
  nextQuizQuestionId: null,
  nextQuizQuestion: null,
  fragrances: []
};

const initialState = {
  quizName: "",
  quizId: null,
  validatedAt: null,
  publishedAt: null,
  campaignId: null,
  campaignName: "",
  language: "",
  questions: [],
  fragrancesSelected: [],
  collection: [],
  loading: false,
  question: { ...initialQuestion },
  answer: { ...initialAnswer },
  answerSelectedIndex: null,
  requiredQuestion: false,
  requiredAnswer: false,
  requiredAnswers: false,
  requiredNextQuestion: false,
  requiredFragrance: false,
  maxShots: null,
  userId: null,
  type: "1",
  select: [],
  resetInput: false,
  anamneseId: 0,
  mandatoryAnamnese: false
};

class QuizUpdate extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      translate: null,
      ...initialState,
      confirmDelete: false,
      id: null,
      treeData: []
    };

    this.api = new QuizApiServiceImpl();
    this.questionApi = new QuestionApiServiceImpl();

    this.initializeTranslation();
    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.handleSelectFragranceAnswer = this.handleSelectFragranceAnswer.bind(
      this
    );
    this.handleSelectNextQuestion = this.handleSelectNextQuestion.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.clearAnswer = this.clearAnswer.bind(this);
    this.editAnswer = this.editAnswer.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.validateQuiz = this.validateQuiz.bind(this);
    this.publishQuiz = this.publishQuiz.bind(this);
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  async getQuiz(id) {
    const quiz = await this.api.get(id);
    if (quiz) {
      this.setState({
        loading: false,
        quizId: quiz.quizId,
        validatedAt: quiz.validatedAt,
        publishedAt: quiz.publishedAt,
        quizName: quiz.name,
        campaignId: quiz.campaignId,
        campaignName: quiz.campaign.name,
        collection: quiz.campaign.collection,
        questions: quiz.questions,
        language: quiz.language,
        question: {
          quizQuestionId: null,
          questionId: null,
          quest: "",
          answers: [],
          type: "",
          fragranceId: "",
          rightAnswer: ""
        },
        answer: { ...initialAnswer },
        maxShots: quiz.maxShots,
        type: quiz.type,
        userId: quiz.userId,
        anamneseId: quiz.anamneseId,
        mandatoryAnamnese: quiz.mandatoryAnamnese
      });
      return quiz;
    } else {
      this.props.history.push("/admin/anamnese");
    }
  }

  async formatTreeData(quiz) {
    let { questions } = quiz;

    const typeQuestion = "question";
    const typeAnswer = "answer";
    const typeFragrance = "fragrance";

    if (!questions.length > 0) {
      return [];
    }

    questions = questions.reverse();

    let questionsFormat = questions.map(q => {
      const { question, answers, questionId, quizQuestionId, quizId } = q;

      return {
        title: (
          <>
            <div
              style={{
                backgroundColor: "cyan",
                display: "inline-block",
                borderRadius: 5,
                color: "#FFF",
                padding: "0 5px",
                marginRight: "0.3rem"
              }}
            >
              Question
            </div>
            {question.quest}
          </>
        ),
        answers,
        question,
        questionId,
        quizQuestionId,
        quizId,
        expanded: true,
        nodeType: typeQuestion,
        children: answers.map(ans => {
          const { answer, fragranceOptions, quizQuestionAnswerId } = ans;

          return {
            title: (
              <>
                <div
                  style={{
                    backgroundColor: "#0D2551",
                    display: "inline-block",
                    borderRadius: 5,
                    color: "#FFF",
                    padding: "0 5px",
                    marginRight: "0.3rem"
                  }}
                >
                  Answer
                </div>
                {answer.label}
              </>
            ),
            nextId: ans.nextQuestion?.question?.questionId,
            quizQuestionAnswerId,
            nodeType: typeAnswer,
            expanded: true,
            children:
              fragranceOptions.length > 0
                ? fragranceOptions.map(frag => {
                    const { fragrance, fragranceOptionId } = frag;
                    return {
                      title: (
                        <>
                          <div
                            style={{
                              backgroundColor: "#0abb87",
                              display: "inline-block",
                              borderRadius: 5,
                              color: "#FFF",
                              padding: "0 5px",
                              marginRight: "0.3rem"
                            }}
                          >
                            Fragrance
                          </div>
                          {fragrance.name}
                        </>
                      ),
                      children: [],
                      nodeType: typeFragrance,
                      fragranceOptionId,
                      noDragging: false,
                      noChildren: true
                    };
                  })
                : []
          };
        })
      };
    });

    let response = await this.questionsFormated(questionsFormat);
    return response;
  }

  questionsFormated(questionsFormat) {
    return new Promise(function(resolve, reject) {
      setTimeout(() => {
        questionsFormat.reverse().forEach(qf => {
          const { questionId } = qf;
          questionsFormat.forEach(q => {
            let { children } = q;
            let childrenFound = children.filter(c => c.nextId === questionId);
            if (childrenFound.length > 0) {
              childrenFound.forEach(cf => {
                cf.children = [qf];
              });
            }
          });
        });
        resolve([questionsFormat[questionsFormat.length - 1]]);
      }, 3000);
    });
  }

  onCleanForm() {
    this.setState({
      loading: false,
      question: {
        quizQuestionId: null,
        questionId: null,
        quest: "",
        answers: [],
        fragranceId: "",
        rightAnswer: ""
      },
      answer: {
        quizQuestionAnswerId: null,
        answerId: null,
        desc: "",
        description: "",
        isAnswerFinish: false,
        nextQuizQuestionId: null,
        nextQuizQuestion: null,
        fragrances: []
      }
    });
  }

  async componentDidMount() {
    await this.initializeTranslation();
    const id = this.props.match.params.id;
    if (!id) {
      return;
    }

    this.setState({ loading: true });

    try {
      let quiz = await this.getQuiz(id);
      let treeData = await this.formatTreeData(quiz);

      const select = [
        {
          key: 1,
          type: "slider",
          name: "Slider"
        },
        {
          key: 2,
          type: "checkbox",
          name: "CheckBox"
        },
        {
          key: 3,
          type: "select",
          name: "Select"
        },
        {
          key: 4,
          type: "radio",
          name: "Radio"
        },
        {
          key: 5,
          type: "text",
          name: "Text"
        }
      ];

      this.setState({ select });

      this.setState({ treeData });
    } catch (error) {
      this.setState({ loading: false });
      Swal.fire(
        this.state.translate("screens.quiz.errors.title"),
        this.state.translate("screens.quiz.errors.message"),
        "error"
      );
    }
  }

  createQuestion = async e => {
    await this.initializeTranslation();
    e.preventDefault();
    try {
      const reqBase = { method: "POST", url: "add-update" };

      const { quizId, question } = this.state;

      let hasError = false;
      if (!question || (!question.questionId && !question.quest)) {
        this.setState({ requiredQuestion: true });
        hasError = true;
      }

      if (question.answers?.length === 0) {
        this.setState({ requiredAnswers: true });
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.setState({ loading: true });

      question.quizId = quizId;
      await this.questionApi.makeHttpRequest({
        ...reqBase,
        data: question
      });

      await this.getQuiz(quizId);
      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("screens.quiz.messages.add"),
        icon: "success",
        cancelButtonText: this.state.translate("buttons.cancel")
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire(
        "ERROR",
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.createQuestion"),
        "error"
      );
    }
  };

  updateQuiz = async () => {
    await this.initializeTranslation();
    const {
      quizId,
      quizName,
      maxShots,
      type,
      language,
      userId,
      campaignId,
      anamneseId,
      mandatoryAnamnese
    } = this.state;
    this.setState({ loading: true });
    try {
      const body = {
        quizId: quizId,
        campaignId: campaignId,
        name: quizName,
        language: language,
        type: type,
        maxShots: maxShots,
        userId: userId,
        anamneseId,
        mandatoryAnamnese
      };
      await this.api.update(quizId, body);
      this.setState({ loading: false });
      await Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("defaultMessages.saveData"),
        icon: "success",
        confirmButtonText: "OK"
      });
    } catch (error) {
      this.setState({ loading: false });
      Swal.fire(
        this.state.translate("screens.quiz.errors.title"),
        this.state.translate("screens.quiz.errors.message"),
        "error"
      );
    }
  };

  handleChangeLanguage = e => {
    this.setState({ language: e.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.maxShots]: e.target.maxShots,
      [e.target.type]: e.target.type
    });
  };

  handleChangeSelect = e => {
    this.setState({
      campaignId: e.id,
      campaignName: e.label,
      resetInput: false
    });
  };

  handleChangeSelectMulti = e => {
    const newId = e.map(usuario => usuario.id).join(";");
    this.setState({
      userId: newId
    });
  };

  handleChangeAnswerDesc = e => {
    const { question } = this.state;
    question.rightAnswer = e.target.value;
    this.setState({
      question
    });
  };

  handleRadio = e => {
    const { answer } = this.state;
    answer.isAnswerFinish = e.target.value === "true";
    this.setState({
      answer
    });
  };

  handleQuestion = obj => {
    const translate = this.context;
    const { question } = this.state;
    question.questionId = obj.id;
    question.quest = obj.label.replace(
      this.state.translate("screens.quiz.placeholders.newQuestion"),
      ""
    );
    this.setState({ question: { ...question }, requiredQuestion: false });
  };

  handleQuestionType = obj => {
    const inputType = obj.target.value;
    const { question } = this.state;
    question.type = inputType;
    this.setState({ question: { ...question }, requiredQuestion: false });
  };

  handleAnswer = obj => {
    const translate = this.context;
    const { answer } = this.state;

    answer.answerId = obj.id;
    answer.desc = obj.label.replace(
      this.state.translate("screens.quiz.placeholders.newAnswer"),
      ""
    );
    answer.description = obj.description ?? "";

    this.setState({ answer, requiredAnswer: false });
  };

  handleSelectFragranceAnswer = obj => {
    const { question } = this.state;
    question.fragranceId = obj.target.value;
    this.setState({ question });
  };

  handleSelectNextQuestion = obj => {
    const { answer } = this.state;
    answer.nextQuizQuestionId = obj.target.value;
    answer.nextQuizQuestion = obj.target;
    this.setState({ answer, requiredNextQuestion: false });
  };

  addAnswer = () => {
    const { question, answer, answerSelectedIndex } = this.state;

    let hasError = false;
    if (!answer || (!answer.answerId && !answer.desc)) {
      this.setState({ requiredAnswer: true });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (answerSelectedIndex !== null) {
      question.answers[answerSelectedIndex] = answer;
    } else {
      question.answers.push(answer);
    }

    this.setState({
      ...this.state,
      question: { ...question },
      answer: { ...initialAnswer },
      answerSelectedIndex: null,
      requiredAnswers: false,
      requiredQuestion: false,
      requiredAnswer: false,
      requiredNextQuestion: false,
      requiredFragrance: false
    });
  };

  formatFragrances(fragrances) {
    const fragrancesOptions = this.state.collection
      .map(e => ({
        label: e.fragrance?.name,
        value: e.fragrance?.fragranceId
      }))
      .find(e => {
        if (parseInt(fragrances) === e.value) {
          return e.label;
        }
      });
    let result = "";
    result = fragrancesOptions?.label;

    return result;
  }

  formatAnswers(answers) {
    let result = [];

    if (answers) {
      result = answers.map((e, i) => <p key={i}>{e.answer?.label}</p>);
    }

    return result;
  }

  clearAnswer(answer) {
    const { question } = this.state;

    const newAnswers = question.answers.filter(e => e !== answer);
    const newQuestion = { ...question, answers: newAnswers };
    this.setState({ question: newQuestion });
  }

  editAnswer(answer, index) {
    const edit = { ...answer };
    this.setState({ answer: edit, answerSelectedIndex: index });
  }

  async onConfirmDelete(quizQuestionId) {
    this.setState({ loading: true });
    await this.questionApi.delete(quizQuestionId);
    await this.getQuiz(this.state.quizId);
    this.setState({ confirmDelete: false });
    this.setState({ loading: false });
  }

  onEdit(obj) {
    const editAnswers = obj.answers?.map(item => ({
      quizQuestionAnswerId: item.quizQuestionAnswerId,
      answerId: item.answerId,
      desc: item.answer?.label,
      description: item.answer?.description,
      isAnswerFinish:
        item.nextQuestionId !== null && item.undefined ? true : false,
      nextQuizQuestionId: item.nextQuestionId,
      nextQuizQuestion: item.nextQuestionId && {
        label: item.nextQuestion?.question?.quest,
        value: item.nextQuestionId
      },
      fragrances: item.fragranceOptions?.map(option => ({
        label: option.fragrance?.name,
        value: option.fragranceId
      }))
    }));

    const editQuestion = {
      quizQuestionId: obj.quizQuestionId,
      questionId: obj.questionId,
      quest: obj.question?.quest,
      type: obj.type,
      answers: editAnswers,
      fragranceId: obj.fragranceId,
      rightAnswer: obj.rightAnswer
    };

    this.setState({ question: { ...editQuestion } });
  }

  handleTreeOnChange = treeData => {
    this.setState({ treeData });
  };

  canDragNode = (node, nextParentNode) => {
    let can = true;
    let nextType = nextParentNode ? nextParentNode.nodeType : null;

    if (node.nodeType === "answer") {
      if (nextType !== "question") can = false;
    } else if (node.nodeType === "question") {
      if (nextType && nextType !== "answer") can = false;
    } else if (node.nodeType === "fragrance") {
      if (nextType !== "answer") can = false;
    }

    return can;
  };

  handleMoveNode = async (treeData, node, nextParentNode) => {
    await this.initializeTranslation();
    if (this.canDragNode(node, nextParentNode)) {
      switch (node.nodeType) {
        case "fragrance":
          try {
            const newQuizQuestionAnswerId = nextParentNode.quizQuestionAnswerId;
            const fragranceOptionId = node.fragranceOptionId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                fragranceOptionId,
                newQuizQuestionAnswerId,
                nodeType: "fragrance"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            Swal.fire({
              title: this.state.translate("defaultMessages.error"),
              text: "Error",
              icon: "error",
              confirmButtonText: "OK"
            });
          }

          this.setState({ loading: false });
          break;
        case "question":
          try {
            const quizQuestionAnswerId = nextParentNode.quizQuestionAnswerId;
            const nextQuestionId = node.quizQuestionId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                quizQuestionAnswerId,
                nextQuestionId,
                nodeType: "question"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            Swal.fire({
              title: this.state.translate("defaultMessages.error"),
              text: "Error",
              icon: "error",
              confirmButtonText: "OK"
            });
          }

          this.setState({ loading: false });

          break;
        case "answer":
          try {
            const newQuizQuestionId = nextParentNode.quizQuestionId;
            const quizQuestionAnswerId = node.quizQuestionAnswerId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                newQuizQuestionId,
                quizQuestionAnswerId,
                nodeType: "answer"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            Swal.fire({
              title: this.state.translate("defaultMessages.error"),
              text: "Error",
              icon: "error",
              confirmButtonText: "OK"
            });
          }

          this.setState({ loading: false });
          break;
        default:
      }

      return true;
    } else {
      Swal.fire({
        title: this.state.translate("defaultMessages.error"),
        text: "Action does not permitted",
        icon: "error"
      });

      setTimeout(() => {
        this.props.history.go();
      }, 2000);
    }

    return false;
  };

  getAnamnese = async () => {
    try {
      this.setState({ loading: true });
      const type = 1;
      const { quizId } = this.state;
      const requestBase = {
        method: "GET",
        url: `anamnese`
      };

      const response = await this.api.makeHttpRequest({ ...requestBase });
      const filterAnamnese = response.filter(value => value.type === type);
    } catch (error) {
      console.error("getAnamnse: ", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  async genericPut(endpoint, msg) {
    await this.initializeTranslation();
    try {
      const { quizId } = this.state;
      const reqBase = { method: "PUT", url: endpoint + "/" + quizId };

      this.setState({ loading: true });

      await this.api.makeHttpRequest({
        ...reqBase
      });

      await this.getQuiz(quizId);
      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: msg,
        icon: "success",
        cancelButtonText: this.state.translate("buttons.cancel")
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire(
        "ERROR",
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.createQuestion"),
        "error"
      );
    }
  }

  async validateQuiz() {
    await this.initializeTranslation();
    await this.genericPut(
      "validate",
      this.state.translate("screens.quiz.messages.validated")
    );
  }

  async publishQuiz() {
    await this.initializeTranslation();
    await this.genericPut(
      "publish",
      this.state.translate("screens.quiz.messages.published")
    );
  }

  toggleConfirmDialog(state) {
    this.setState({ confirmDelete: state });
  }

  checkMandatoryAnamnese = event => {
    const { checked } = event.target;
    this.setState({ mandatoryAnamnese: checked });
  };

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate("screens.quiz.pageTitle.edit")} - ${translate(
        "labels_admin"
      )}`
    );

    const {
      quizName,
      campaignName,
      language,
      questions,
      question,
      answer,
      collection,
      answerSelectedIndex,
      requiredQuestion,
      requiredAnswers,
      requiredAnswer,
      requiredNextQuestion,
      requiredFragrance,
      validatedAt,
      publishedAt,
      maxShots,
      userId,
      type,
      select,
      resetInput,
      anamneseId,
      mandatoryAnamnese
    } = this.state;

    const fragrancesOptions = collection
      .map(e => ({ label: e.fragrance?.name, value: e.fragrance?.fragranceId }))
      .filter(e => {
        let found = false;
        for (var i = 0; i < answer.fragrances.length; i++) {
          if (
            answer.fragrances[i].label === e.label &&
            answer.fragrances[i].value === e.value
          ) {
            found = true;
            break;
          }
        }
        if (!found) return e;
      });

    const questionOptions = questions.map(e => ({
      value: e.quizQuestionId,
      label: e.question?.quest
    }));

    const headRows = [
      { label: translate("screens.quiz.list.actions") },
      {
        column: "Question",
        label: translate("screens.quiz.labels.question")
      },
      {
        column: "Answers",
        label: translate("screens.quiz.labels.answer")
      },
      {
        column: "RightAnswers",
        label: translate("screenApp.results.rightResponse")
      },
      {
        column: "Type",
        label: translate("screenApp.quiz.questionType")
      },
      {
        column: "FragranceId",
        label: translate("screens.fragrance.referenceFragrance")
      },
      {
        column: "CreatedAt",
        label: translate("screens.quiz.list.created")
      },
      {
        column: "UpdatedAt",
        label: translate("screens.quiz.list.updated")
      }
    ];

    const formatRow = r => (
      <TableRow hover tabIndex={-1} key={r.quizQuestionId}>
        <TableCell>
          <CrudActions
            actions={["edit", "delete"]}
            module="question"
            onConfirmDelete={this.onConfirmDelete}
            onEdit={this.onEdit}
            id={r.quizQuestionId}
            obj={r}
          />
        </TableCell>
        <TableCell scope="row">{r.question?.quest}</TableCell>
        <TableCell scope="row">{this.formatAnswers(r.answers)}</TableCell>
        <TableCell scope="row">{r.rightAnswer}</TableCell>
        <TableCell scope="row">{r.type}</TableCell>
        <TableCell scope="row">
          {this.formatFragrances(r.fragranceId)}
        </TableCell>
        <TableCell scope="row">
          {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
      </TableRow>
    );

    return (
      <>
        <ConfirmDeleteDialog
          open={this.state.confirmDelete}
          closeDialog={() => this.toggleConfirmDialog(false)}
          confirm={() => this.onConfirmDelete(this.state.id)}
        />
        <form>
          <Loading isLoading={this.state.loading} />
          <Wrapper
            className="card-form"
            headerTitle={translate("screens.quiz.labels.principal")}
          >
            <div className="card-body">
              <Row>
                <FormInput
                  label={translate("screens.user.labels.name")}
                  name="quizName"
                  value={quizName}
                  onChange={this.handleChange}
                  required
                  placeholder={translate("screens.user.placeholders.name")}
                  errorMessage={translate(
                    "screens.category.labels.invalide.name"
                  )}
                  isInvalid={quizName && !IsValidName(quizName)}
                  className="col-lg-3 mb-0 pt-2"
                />

                <div className="col-lg-6 col-12 mb-0 pt-2">
                  <div className="form-label">
                    {translate("screens.quiz.labels.campaign")} *
                  </div>
                  <SelectSearchComponent
                    resetInput={resetInput}
                    required={true}
                    handleChange={e => this.handleChangeSelect(e)}
                    className="kt-width-full"
                    label={translate("screens.quiz.placeholders.campaign")}
                    url={`/campaigns/without-quiz?str=`}
                    value={{ label: campaignName }}
                    convertObject={obj => ({
                      id: obj.campaignId,
                      value: obj.campaignId,
                      label: `${obj.name}`,
                      obj
                    })}
                    translate={translate}
                  />
                </div>
                <div className="col-lg-3 col-12 mb-0 pt-2">
                  <div className="form-label">
                    {translate("screens.quiz.labels.language")}
                  </div>
                  <SelectLanguage
                    handleChangeLanguage={this.handleChangeLanguage}
                    languageValue={language}
                  />
                </div>
              </Row>
              <Row>
                <div className="col-lg- col-8 mb-0 pt-2">
                  <StrictMode>
                    <TransferList
                      endPoint={`usuario`}
                      value={userId}
                      handleMultiSelect={this.handleChangeSelectMulti}
                    />
                  </StrictMode>
                </div>
              </Row>
            </div>
            <Card.Footer>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={this.updateQuiz}
                  style={{
                    width: "100%",
                    color: "#fff"
                  }}
                >
                  {translate("buttons.edit")}
                </Button>
              </Stack>
            </Card.Footer>
          </Wrapper>
          <Wrapper
            className="card-form"
            headerTitle={translate("screens.quiz.labels.questionarie")}
          >
            <div className="card-body">
              <Row>
                <div className="col-lg-12 col-12 mb-1">
                  <Row>
                    <div className="col-lg-8 col-12 mb-1">
                      <div className="form-label">
                        {translate("screens.quiz.labels.question")}
                      </div>
                      <SelectSearchComponent
                        value={
                          question.questionId && {
                            id: question.questionId,
                            value: question.questionId,
                            label: question.quest
                          }
                        }
                        required={
                          requiredQuestion ? { status: "invalid" } : null
                        }
                        resetInput={question.quest === ""}
                        handleChange={this.handleQuestion}
                        name="question"
                        className="kt-width-full"
                        label={translate("screens.quiz.placeholders.question")}
                        url={`/question/by-name?language=${language}&str=`}
                        convertObject={obj => ({
                          id: obj.questionId,
                          value: obj.questionId,
                          label: obj.quest
                        })}
                        createLabelText={translate(
                          "screens.quiz.placeholders.newQuestion"
                        )}
                        newOption={true}
                      />
                      {requiredQuestion ? (
                        <div
                          className="form-label"
                          style={{ color: "#FF0000" }}
                        >
                          {translate("screens.quiz.errors.questionRequired")}
                        </div>
                      ) : null}
                    </div>

                    <FormInput
                      label={translate("screenApp.quiz.questionType")}
                      name="type"
                      value={question.type}
                      onChange={this.handleQuestionType}
                      className="col-lg-4 mb-1"
                      type="select"
                      Options={
                        <>
                          <option value="">
                            {translate("screenApp.quiz.selectOption")}
                          </option>
                          {select.map(p => (
                            <option key={p.type} value={p.type}>
                              {p.name}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </Row>
                  <Row>
                    <div className="col-lg-8 col-12 mb-1">
                      <div className="form-label">
                        {translate("screens.quiz.labels.answer")}
                      </div>
                      <SelectSearchComponent
                        resetInput={answer.desc === ""}
                        handleChange={this.handleAnswer}
                        name="answer"
                        className="kt-width-full"
                        label={translate("screens.quiz.placeholders.answer")}
                        url={`/answer/by-name?language=${language}&str=`}
                        convertObject={obj => ({
                          id: obj.answerId,
                          value: obj.answerId,
                          label: obj.label,
                          description: obj?.description
                        })}
                        newOption={true}
                        createLabelText={translate(
                          "screens.quiz.placeholders.newAnswer"
                        )}
                      />
                      {requiredAnswer ? (
                        <div
                          className="form-label"
                          style={{ color: "#FF0000" }}
                        >
                          {translate("screens.quiz.errors.answerRequired")}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-lg-4 col-12 mb-0 pt-2">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={this.addAnswer}
                        className="mt-4"
                        style={{ color: "#fff" }}
                      >
                        {answerSelectedIndex !== null
                          ? translate("screens.quiz.labels.updateAnswer")
                          : translate("screens.quiz.labels.addAnswer")}
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    <FormInput
                      label={translate("screenApp.results.rightResponse")}
                      name="rightAnswer"
                      value={question?.rightAnswer || ""}
                      onChange={this.handleChangeAnswerDesc}
                      className="col-lg-6 mb-1"
                      type="select"
                      Options={
                        <>
                          <option value="">
                            {translate("screenApp.quiz.selectOption")}
                          </option>
                          {question?.answers?.map(p => (
                            <option
                              key={p.desc}
                              value={p.desc}
                              selected={p.desc === question?.rightAnswer}
                            >
                              {p.desc}
                              {" / "}
                              {this.formatFragrances(p.description, collection)}
                            </option>
                          ))}
                        </>
                      }
                    />
                    {answer.isAnswerFinish ? (
                      <div className="col-lg-6 col-12 mb-1">
                        <div className="form-label">
                          {translate("screens.fragrance.referenceFragrance")}
                        </div>
                        <Select
                          value={this.state.question.fragranceId || ""}
                          name="fragrancesSelected"
                          onChange={this.handleSelectFragranceAnswer}
                          displayEmpty
                          style={{ height: 38.4, display: "flex" }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="" disabled>
                            {translate("screens.quiz.placeholders.fragrances")}
                          </MenuItem>
                          {fragrancesOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              <Box display="flex">
                                <Typography ml={option.label ? 1 : 3}>
                                  {option.label}
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                        {answer.isAnswerFinish && requiredFragrance ? (
                          <div
                            className="form-label"
                            style={{ color: "#FF0000" }}
                          >
                            {translate("screens.quiz.errors.fragranceRequired")}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="col-lg-6 col-12 mb-1">
                        <div className="form-label">
                          {translate("screens.quiz.labels.next")}
                        </div>
                        <Select
                          value={this.state.answer.nextQuizQuestionId || ""}
                          name="questionsToLink"
                          onChange={this.handleSelectNextQuestion}
                          displayEmpty
                          style={{ height: 38.4, display: "flex" }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="" disabled>
                            {translate("screens.quiz.placeholders.next")}
                          </MenuItem>
                          {questionOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              <Box display="flex">
                                <Typography ml={option.label ? 1 : 3}>
                                  {option.label}
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                        {!answer.isAnswerFinish && requiredNextQuestion ? (
                          <div
                            className="form-label"
                            style={{ color: "#FF0000" }}
                          >
                            {translate("screens.quiz.errors.nextRequired")}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Row>
                </div>
              </Row>
              <Row>
                <TableAnswers
                  answers={question.answers}
                  clearAnswer={a => this.clearAnswer(a)}
                  collection={collection}
                  editAnswer={(a, i) => this.editAnswer(a, i)}
                  requiredAnswers={requiredAnswers}
                  formType={type}
                >
                  <ButtonsQuiz
                    createQuestion={this.createQuestion}
                    onCleanForm={this.onCleanForm}
                    publishQuiz={this.publishQuiz}
                    publishedAt={publishedAt}
                    question={question}
                    questions={questions}
                    validateQuiz={this.validateQuiz}
                    validatedAt={validatedAt}
                  />
                </TableAnswers>
              </Row>
              <TableQuestions
                collection={collection}
                onConfirmDelete={this.onConfirmDelete}
                onEdit={this.onEdit}
                questions={questions}
                formType={type}
              />
              {/* <DatatableStandalone
                headRows={headRows}
                formatRow={formatRow}
                rows={questions}
              /> */}
            </div>
          </Wrapper>
        </form>
      </>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

const connected = connect(mapStoreToProps, null)(QuizUpdate);

export default withRouter(withFormValidation(connected, initialState));
