import axios from "axios";
import { HttpService } from "../HttpService";
import {
  CategoryCognitiveQuestionType,
  CognitiveCategoryType
} from "app/types";

type PluralRulesType = {
  length: number;
  equation: string;
  forms: string[];
};

type TranslationProgressType = {
  translated: number;
  untranslated: number;
  flagged: number;
  words: number;
};

export type LocaleType = {
  code: string;
  name: string;
  source: boolean;
  native: boolean;
  plurals: PluralRulesType;
  progress: TranslationProgressType;
};

const API_KEY = "msZYtfXEGxDnjQETe8nqysxlmu3Hc7LU";
const BASE_URL = "https://noar-localise-api.azurewebsites.net/api";

export const getLocales = async (): Promise<LocaleType[] | undefined> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/localise/languages?key=${API_KEY}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro get locales", error);
  }
};

export const fetchAllLocales = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/localise/locales?key=${API_KEY}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro fetch all locales", error);
  }
};

export class LocaliseAPIService extends HttpService {
  private constructor() {
    const instance = axios.create({
      baseURL: BASE_URL
    });

    super(instance);
  }

  static getInstance(): LocaliseAPIService {
    return new LocaliseAPIService();
  }

  async getCategories(): Promise<CognitiveCategoryType[] | undefined> {
    const data: CategoryCognitiveQuestionType = await this.makeHttpRequest({
      url: "/categoryCognitiveQuestion"
    });

    return data.categoryCognitiveQuestion;
  }
}
