import { SelectInputType } from "mocks/quizInputs";
import * as yup from "yup";

export const schema = yup.object().shape({
  quest: yup.string().required("A pergunta é obrigatória"),
  type: yup.string<SelectInputType>().required("O tipo é obrigatório"),
  answers: yup
    .array()
    .of(
      yup.object().shape({
        desc: yup.string().required("A resposta é obrigatória")
      })
    )
    .min(1, "É necessário ter ao menos uma resposta"),
  fragranceId: yup
    .string()
    .when(["type", "quizTypeKey"], (values: Array<any>) => {
      if (String(values[1]).includes("7")) {
        return yup.string().nullable();
      }
      if (!values[0].includes("radio")) {
        return yup
          .string()
          .required("A fragrância é obrigatória quando o tipo não é rádio");
      }
      return yup.string().nullable();
    }),
  quizTypeKey: yup.string(),
  rightAnswer: yup.string().when("quizTypeKey", (quizTypeKey: any) => {
    if (!String(quizTypeKey).includes("7")) {
      return yup.string().required("A resposta correta é obrigatória");
    }
    return yup.string().nullable();
  })
});
