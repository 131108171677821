import { Col, Form } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";
import { CollectionType } from "app/types";
import { SelectInputType } from "mocks/quizInputs";
import { QuestionFormType } from "../update-type";

type CollectionFragranceInputProps = {
  selectedType: SelectInputType;
  label: string;
  index: number;
  control: Control<QuestionFormType, any>;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  defaultSelectedOption: string;
  collections: CollectionType[];
};

const CollectionFragranceInput = ({
  selectedType,
  label,
  index,
  control,
  styleInput,
  defaultSelectedOption,
  collections
}: CollectionFragranceInputProps) => {
  if (selectedType !== "radio") {
    return null;
  }

  return (
    <Form.Group as={Col} lg="12" xs="12" className="mb-0 pt-2">
      <Form.Label>{label}</Form.Label>
      <Controller
        name={`answers.${index}.description`}
        control={control}
        render={({ field }) => (
          <Form.Select {...field} style={styleInput}>
            <option value="">{defaultSelectedOption}</option>
            {collections.map(collection => (
              <option
                key={collection.fragrance.fragranceId}
                value={collection.fragrance.fragranceId}
              >
                {collection.fragrance.name}
              </option>
            ))}
          </Form.Select>
        )}
      />
    </Form.Group>
  );
};

export default CollectionFragranceInput;
