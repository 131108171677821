import {
  Box,
  Button,
  DialogContent,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister
} from "react-hook-form";
import { Add, Remove } from "@mui/icons-material";

import CollectionFragranceInput from "./CollectionFragranceInput";
import { SelectInputType } from "mocks/quizInputs";
import {
  AnswerType,
  CognitiveCategoryType,
  CollectionType,
  QuestionType,
  QuizType
} from "app/types";
import ContentQuestionFormView from "./ContentQuestionFormView";
import { QuestionFormType } from "../update-type";

type DialogContentViewProps = {
  errors: FieldErrors<QuestionType>;
  answers: Array<AnswerType>;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  control: Control<QuestionFormType, any>;
  selectedType: SelectInputType;
  collections: Array<CollectionType>;
  fields: FieldArrayWithId<QuestionType, "answers", "id">[];
  cognitiveCategories: Array<CognitiveCategoryType>;
  quiz: QuizType;
  preViewImage: string;
  cognitiveInputs: Array<{ type: string; name: string }>;
  translate: (key: string) => string;
  showModalAnswersImage: () => void;
  append: UseFieldArrayAppend<QuestionFormType, "answers">;
  remove: UseFieldArrayRemove;
  handleDescChange: (index: number, value: string) => void;
  register: UseFormRegister<QuestionFormType>;
  handleFileQuestion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearQuest: () => void;
  handleQuestChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const DialogContentView = ({
  translate,
  showModalAnswersImage,
  errors,
  answers,
  styleInput,
  control,
  append,
  selectedType,
  collections,
  remove,
  handleDescChange,
  register,
  fields,
  cognitiveCategories,
  quiz,
  handleFileQuestion,
  preViewImage,
  cognitiveInputs,
  handleClearQuest,
  handleQuestChange
}: DialogContentViewProps) => {
  const checkanswers =
    !!answers.length &&
    answers.every(answer => answer.quizQuestionAnswerId > 0) &&
    selectedType === "image";

  const helperText = (text?: string) => (
    <Form.Text className="text-danger">{text}</Form.Text>
  );

  return (
    <DialogContent>
      <ContentQuestionFormView
        cognitiveCategories={cognitiveCategories}
        cognitiveInputs={cognitiveInputs}
        collections={collections}
        control={control}
        errors={errors}
        handleClearQuest={handleClearQuest}
        handleFileQuestion={handleFileQuestion}
        handleQuestChange={handleQuestChange}
        helperText={helperText}
        preViewImage={preViewImage}
        quiz={quiz}
        register={register}
        selectedType={selectedType}
        styleInput={styleInput}
        translate={translate}
      />
      <Divider style={{ backgroundColor: "#757575", marginTop: 20 }} />
      <Typography variant="h6" mt={2} mb={2}>
        {translate("labels_answers")}
      </Typography>
      {fields.map((item, index) => (
        <Box key={item.id} mb={2}>
          <Row>
            <Form.Group as={Col} lg={"8"} xs="12" className="mb-0 pt-1">
              <Form.Label>
                {translate("screens.quiz.labels.answer")}*
              </Form.Label>
              <Form.Control
                disabled={false}
                placeholder={translate("screens.quiz.labels.answer")}
                {...register(`answers.${index}.desc` as const)}
                onChange={e => handleDescChange(index, e.target.value)}
                {...(errors.answers &&
                  helperText(errors.answers[index]?.desc?.message))}
                style={styleInput}
              />
            </Form.Group>
            <Form.Group as={Col} lg={"4"} xs="12" className="mb-0 pt-4">
              <Form.Label></Form.Label>
              <Button
                variant="outlined"
                color="error"
                startIcon={<Remove />}
                onClick={() => remove(index)}
                sx={{ marginTop: 1 }}
              >
                {translate("screens_quiz_labels_deleteAnswer")}
              </Button>
            </Form.Group>
            <CollectionFragranceInput
              collections={collections}
              control={control}
              defaultSelectedOption={translate(
                "screens.quiz.placeholders.fragrances"
              )}
              index={index}
              label={translate("screens.fragrance.answerReferenceFragrance")}
              selectedType={selectedType}
              styleInput={styleInput}
            />
          </Row>
        </Box>
      ))}
      <Stack spacing={2}>
        <Divider style={{ backgroundColor: "#757575" }} />
        <Button
          variant="outlined"
          color="info"
          startIcon={<Add />}
          onClick={() =>
            append({
              quizQuestionAnswerId: 0,
              answerId: 0,
              desc: "",
              description: "",
              nextQuizQuestionId: 0
            })
          }
        >
          {translate("screens_quiz_labels_addAnswer")}
        </Button>
      </Stack>
      <Form.Group as={Col} lg={"12"} xs="12" className="mb-0 pt-2">
        <Form.Label>{translate("screenApp.results.rightResponse")}</Form.Label>
        <Controller
          name="rightAnswer"
          control={control}
          render={({ field }) => (
            <Form.Select {...field} style={styleInput}>
              <option value="">
                {translate("screenApp.quiz.selectOption")}
              </option>
              {answers.map((item, index) => (
                <option key={index} value={item.desc}>
                  {item.desc}
                </option>
              ))}
            </Form.Select>
          )}
        />
        {errors.rightAnswer && helperText(errors?.rightAnswer.message)}
      </Form.Group>
      {checkanswers && (
        <Stack spacing={2}>
          <Divider style={{ backgroundColor: "#757575" }} />
          <Button
            variant="outlined"
            color="inherit"
            onClick={showModalAnswersImage}
          >
            {translate("screens_quiz_answer_image_edit")}
          </Button>
        </Stack>
      )}
    </DialogContent>
  );
};

export default DialogContentView;
