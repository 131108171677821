import { Col, Form } from "react-bootstrap";
import { UseFormRegisterReturn } from "react-hook-form";
import { SelectInputType } from "mocks/quizInputs";

type InstructionInputProps = {
  register: UseFormRegisterReturn<"instruction">;
  selectedType: SelectInputType;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
};

const InstructionInput = ({
  register,
  styleInput,
  selectedType
}: InstructionInputProps) => {
  const excludeInput = !["speechtotext", "voice"].includes(selectedType);

  if (excludeInput) {
    return null;
  }

  return (
    <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
      <Form.Label>Instrução</Form.Label>
      <Form.Control {...register} style={styleInput} />
    </Form.Group>
  );
};

export default InstructionInput;
