import React from "react";

import useQuizFormModel from "./hooks/useQuizFormNewModel";
import QuizNewView from "./quiznew-view";
import {
  CampaignApiServiceImpl,
  QuestionApiServiceImpl,
  QuizApiServiceImpl
} from "app/services";

const QuizFormNew: React.FC = () => {
  const campaignService = new CampaignApiServiceImpl();
  const quizService = new QuizApiServiceImpl();
  const questionService = new QuestionApiServiceImpl();
  const model = useQuizFormModel({
    campaignService,
    quizService,
    questionService
  });

  return <QuizNewView {...model} />;
};

export default QuizFormNew;
