import * as React from "react";
import { useEffect } from "react";
import {  Spinner } from "react-bootstrap";
import { AnanseApiService } from "../../../../../services/Ananse/AnanseApiService";
import { UseFormRegisterReturn } from "react-hook-form";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import { Form } from "app/pages/admin/components";

const api = new AnanseApiService();

interface Props {
  defaultValue?: string | number;
  url: string;
  placeholder: string;
  register: UseFormRegisterReturn;
  multiple?: boolean;
  convertData?: (selectedItems: { value: string; label: string }[]) => string;
  convertResultData?: (data: any) => { value: string; label: string };
  value?: string | number | readonly string[] | undefined;
}

const SelectComponent = ({
  url,
  defaultValue = "",
  placeholder,
  register,
  multiple = false,
  convertData,
  convertResultData = (data: any) => ({ value: data.quizId, label: data.name }),
  value,
}: Props) => {
  const { isDarkMode } = useThemeApp();
  const [data, setData] = React.useState<
    Array<{ value: string; label: string }>
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<
    Array<{ value: string; label: string }>
  >([]);

  const getListFromValue = async () => {
    setLoading(true);
    let searchedList: Array<{ value: string; label: string }> = [];
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/${url}`,
      });
      if (response.total) {
        const responseData = await api.makeHttpRequest({
          method: "GET",
          url: `/${url}?perpage=${response.total}`,
        });
        searchedList = responseData.data.map((item: any) =>
          convertResultData(item),
        );
      } else if (response.length) {
        searchedList = response.map((item: any) => convertResultData(item));
      }
    } catch (error) {
      console.error(`Erro ao fazer a busca: ${error}`);
    } finally {
      setData(searchedList);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListFromValue();
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement> & {
      target: HTMLSelectElement;
    },
  ) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const selectedValues = selectedOptions.map(option => ({
      value: option.value,
      label: option.text,
    }));

    setSelectedItems(selectedValues);

    const convertedData = convertData
      ? convertData(selectedValues)
      : selectedValues.map(item => item.value).join(";");

    register.onChange({
      target: {
        name: register.name,
        value: convertedData,
      },
    });
  };
  const styleInput = {
    backgroundColor: isDarkMode ? "#1e1e1e" : "#fff",
    color: isDarkMode ? "#ffffff" : "#747a91",
  };

  return (
    <Form.Group>
      <Form.Label>{placeholder}</Form.Label>
      <Form.Select
        multiple={multiple}
        disabled={loading}
        {...register} // Spread de props do register para controlar o input
        onChange={event => handleChange(event as any)} // Correção de tipagem
        style={styleInput}
        value={value}
        defaultValue={defaultValue === "" ? "" : defaultValue}
      >
        {loading ? (
          <option value="0">
            <Spinner animation="border" size="sm" role="status" />
            Carregando...
          </option>
        ) : (
          <>
            <option value={defaultValue}>Nenhum</option>
            {data.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </>
        )}
      </Form.Select>
      {multiple && selectedItems.length > 0 && (
        <Form.Label>
          {selectedItems.map(item => item.label).join(", ")}
        </Form.Label>
      )}
    </Form.Group>
  );
};

export default SelectComponent;
