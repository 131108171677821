import { FC } from "react";
import styled from "styled-components";

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

interface BoldProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
}

const StyledParagraph = styled.p`
  font-size: 1em;
  color: ${({ theme }) => theme.text};
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;

const StyledBold = styled.b`
  font-size: 1.1em;
  color: ${({ theme }) => theme.text};
  display: inline;
`;

const Paragraph: React.FC<ParagraphProps> & {
  Bold: React.FC<BoldProps>;
} = ({ children, ...props }) => {
  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

Paragraph.Bold = ({ children, ...props }: BoldProps) => (
  <StyledBold {...props}>{children}</StyledBold>
);

export default Paragraph;
