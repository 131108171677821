import React from "react";
import { connect } from "react-redux";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import { QuizApiServiceImpl } from "../../../../services/Ananse/impl/QuizApiService";
import { TranslationContext } from "_metronic/i18n/language";

class AnamneseListing extends React.Component {

  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.api = new QuizApiServiceImpl();
    this.state = {
      translate: null,
      quiz: [],
      reload: false,
      isActive: "true"
    };

    this.setQuiz = this.setQuiz.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    // Aguarde até que o contexto seja definido
    const translate = await this.context;
    this.setState({ translate });
  }

  setQuiz(quiz) {
    this.setState({ quiz: quiz.filter(value => value.type === "2") });
  }

  async onConfirmChangeStatus(obj) {
    if (!obj.deletedAt) {
      await this.api.delete(obj.quizId);
    } else {
      await this.api.activate(obj.quizId);
    }

    this.setState({ reload: !this.state.reload });
  }

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate("screens_testAnamnese")} - ${translate("labels_admin")}`
    );

    const headRows = [
      { label: translate("screens.quiz.list.actions") },
      { column: "Name", label: translate("screens.quiz.list.name") },
      {
        column: "Campaign.Name",
        label: translate("screens.quiz.list.campaign")
      },
      {
        column: "Language",
        label: translate("screens.quiz.labels.language")
      },
      {
        column: "Questions",
        label: translate("screens.quiz.list.questions")
      },
      {
        column: "PublishedAt",
        label: translate("screens_fragrance_labels_type")
      },
      {
        column: "CreatedAt",
        label: translate("screens.quiz.list.created")
      },
      {
        column: "UpdatedAt",
        label: translate("screens.quiz.list.updated")
      }
    ];

    const formatRow = r => (
      <TableRow hover tabIndex={-1} key={r.quizId}>
        <TableCell>
          <CrudActions
            actions={["toggle", "edit"]}
            module="anamnese"
            onConfirmChangeStatus={this.onConfirmChangeStatus}
            id={r.quizId}
            obj={r}
          />
        </TableCell>
        <TableCell scope="row">{r.name}</TableCell>
        <TableCell scope="row">{r.campaign && r.campaign.name}</TableCell>
        <TableCell scope="row">{r.language}</TableCell>
        <TableCell scope="row">{r.questions && r.questions.length}</TableCell>
        <TableCell scope="row">
          {r.type === "2" ? "Teste de Anamnese" : "Teste de cheiro"}
        </TableCell>
        <TableCell scope="row">
          {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
      </TableRow>
    );

    return (
      <Datatable
        title={translate("screens_testAnamnese")}
        endpoint="/quiz?Type=2"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.quiz}
        setRows={this.setQuiz}
        reload={this.state.reload}
        placeholderSearch={
          translate("screens.quiz.placeholders.search")
        }
        isActive={this.state.isActive}
        buttons={[
          {
            label: translate("datatable.add"),
            onClick: null,
            icone: "add",
            path: "/admin/anamnese/create"
          }
        ]}
      />
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(AnamneseListing);
