import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("O nome é obrigatório"),
  campaignId: yup
    .number()
    .required("A campanha é obrigatória")
    .positive("O número deve ser positivo")
    .integer("Deve ser um número inteiro")
    .typeError("Selecione uma campanha válida"),
  type: yup.string().required("O tipo é obrigatório"),
  maxShots: yup
    .number()
    .required("Informe o número máximo de tentativas")
    .min(0, "O número de tentativas não pode ser negativo")
    .integer("Deve ser um número inteiro")
});
