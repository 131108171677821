import { FormEventHandler } from "react";
import { Dialog, DialogTitle, Divider } from "@mui/material";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";

import { SelectInputType } from "mocks/quizInputs";
import {
  AnswerType,
  CognitiveCategoryType,
  CollectionType,
  QuestionType,
  QuizType,
} from "app/types";
import { DialogFormView } from "./";
import { QuestionFormType } from "../update-type";

type ModalDialogViewProps = {
  open: boolean;
  sequence: number;
  errors: FieldErrors<QuestionType>;
  answers: Array<AnswerType>;
  selectedType: SelectInputType;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  control: Control<QuestionFormType, any>;
  collections: Array<CollectionType>;
  fields: FieldArrayWithId<QuestionType, "answers", "id">[];
  quiz: QuizType;
  preViewImage: string;
  cognitiveInputs: Array<{ type: string; name: string }>;
  cognitiveCategories: Array<CognitiveCategoryType>;
  onClose: () => void;
  translate: (key: string) => string;
  clearImageQuestion: () => void;
  showModalAnswersImage: () => void;
  append: UseFieldArrayAppend<QuestionFormType, "answers">;
  remove: UseFieldArrayRemove;
  handleDescChange: (index: number, value: string) => void;
  register: UseFormRegister<QuestionFormType>;
  handleFileQuestion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearQuest: () => void;
  handleQuestChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};

const ModalDialogView = ({
  open,
  translate,
  clearImageQuestion,
  onClose,
  showModalAnswersImage,
  errors,
  answers,
  selectedType,
  styleInput,
  control,
  append,
  collections,
  remove,
  handleDescChange,
  register,
  fields,
  quiz,
  handleFileQuestion,
  preViewImage,
  cognitiveInputs,
  handleClearQuest,
  handleQuestChange,
  onSubmit,
  sequence,
  cognitiveCategories,
}: ModalDialogViewProps) => {
   return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle>
        {translate("screens_quiz_labels_questionarie")}
        {" - "}
        {sequence}
      </DialogTitle>
      <Divider style={{ backgroundColor: "#757575" }} />
      <DialogFormView
        answers={answers}
        append={append}
        clearImageQuestion={clearImageQuestion}
        cognitiveInputs={cognitiveInputs}
        collections={collections}
        control={control}
        errors={errors}
        fields={fields}
        handleClearQuest={handleClearQuest}
        handleDescChange={handleDescChange}
        handleFileQuestion={handleFileQuestion}
        handleQuestChange={handleQuestChange}
        onClose={onClose}
        onSubmit={onSubmit}
        preViewImage={preViewImage}
        quiz={quiz}
        register={register}
        remove={remove}
        selectedType={selectedType}
        showModalAnswersImage={showModalAnswersImage}
        styleInput={styleInput}
        translate={translate}
        cognitiveCategories={cognitiveCategories}
      />
    </Dialog>
  );
};

export default ModalDialogView;
