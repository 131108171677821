import store from "../../../app/store/store";
import GroupIcon from "@mui/icons-material/Group";

type MenuItemType = {
  title: string;
  root?: boolean;
  icon?: string;
  iconMui?: string;
  bullet?: string;
  page?: string;
  submenu?: MenuItemType[];
  alignment?: "left" | "right" | "top" | "bottom";
  translate?: string;
};

type MenuType = {
  header: {
    self: any;
    items: MenuItemType[];
  };
  aside: {
    self: any;
    items: MenuItemType[];
  };
};

export default (translate: (k: string) => string) => {
  const { auth } = store.getState();
  const isAdmin = auth && auth.user && auth.user.profile.nome === "ADMIN";
  const isGrouper =
    auth && auth.user && auth.user.grouper && auth.user.usersLicense > 0;

  const menu: MenuType = {
    header: {
      self: {},
      items: [
        {
          title: translate("menu.header.items.profiles"),
          root: true,
          alignment: "left",
          page: "perfis",
        },
      ],
    },
    aside: {
      self: {},
      items: [],
    },
  };

  if (isAdmin) {
    menu.aside.items.push({
      title: translate("menu.user.title"),
      root: true,
      icon: "flaticon2-group",
      iconMui: "users",
      bullet: "dot",
      submenu: [
        {
          title: translate("menu.user.title"),
          page: "admin/users",
        },
        {
          title: translate("labels_associate_tests"),
          page: "admin/userlist",
        },
      ],
    });

    menu.aside.items.push({
      title: translate("menu.fragrance.title"),
      root: true,
      icon: "flaticon2-wifi",
      iconMui: "fragrances",
      bullet: "dot",
      submenu: [
        {
          title: translate("menu.fragrance.submenu.categories"),
          page: "admin/categories",
        },
        {
          title: translate("menu.fragrance.submenu.config"),
          page: "admin/fragrances",
        },
      ],
    });

    menu.aside.items.push({
      title: translate("menu.campaign.title"),
      root: true,
      icon: "flaticon2-writing",
      iconMui: "campaign",
      bullet: "dot",
      page: "admin/campaigns",
    });

    menu.aside.items.push({
      title: translate("menu.quiz.title.quiz"),
      root: true,
      icon: "flaticon2-chat",
      iconMui: "quiz",
      bullet: "dot",
      submenu: [
        {
          title: translate("menu.quiz.title.quiz"),
          page: "admin/quiz",
        },
        {
          title: translate("screenApp_anamnese_list_anamneseTitle"),
          page: "admin/anamnese",
        },
      ],
    });
  }

  menu.aside.items.push({
    title: translate("screens.device.title"),
    root: true,
    icon: "flaticon2-pie-chart-2",
    iconMui: "devices",
    bullet: "dot",
    page: "admin/devices",
  });

  menu.aside.items.push({
    title: translate("screens.capsule.title"),
    root: true,
    icon: "flaticon2-safe",
    iconMui: "capsules",
    bullet: "dot",
    page: "admin/capsules",
  });

  menu.aside.items.push({
    title: translate("screenApp.listPacient.account.titleList"),
    root: true,
    icon: "flaticon-users",
    iconMui: "switchAccount",
    bullet: "dot",
    page: "admin/account",
  });

  menu.aside.items.push({
    title: translate("dashboard_label"),
    root: true,
    icon: "flaticon2-chart",
    iconMui: "dashboard",
    page: "admin/dashboard",
    translate: "MENU.DASHBOARD",
    bullet: "dot",
    submenu: [
      { title: translate("dashboard_label_one"), page: "admin/dashboard" },
      { title: translate("dashboard_label_two"), page: "admin/dsa" },
      { title: translate("dashboard_label_three"), page: "admin/dsaresult" },
      {
        title: translate("dashboard_label_four"),
        page: "admin/dashboard-export-quizanswers",
      },
    ],
  });

  if (isGrouper || isAdmin) {
    menu.aside.items.push({
      title: translate("screens.group.title"),
      root: true,
      icon: "flaticon-analytics",
      iconMui: "groups",
      bullet: "dot",
      page: "admin/mygroup",
    });
  }

  if (isAdmin) {
    menu.aside.items.push({
      title: translate("screenApp_backup_import_label"),
      root: true,
      icon: "flaticon2-download",
      iconMui: "import",
      bullet: "dot",
      page: "admin/import",
    });

    menu.aside.items.push({
      // title: translate("screenApp_backup_import_label"),
      title: "Corrigir resposta",
      root: true,
      icon: "flaticon2-download",
      iconMui: "repair",
      bullet: "dot",
      page: "admin/cognitive",
    });
  }

  return menu;
};
