import { Form, Col } from "react-bootstrap";
import { FieldError, UseFormRegister } from "react-hook-form";
import { QuizType } from "app/types";

type MaxShotsInputProps = {
  isVisible: boolean;
  translate: (key: string) => string;
  register: UseFormRegister<QuizType>;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  errors?: FieldError;
  helperText: (errorMessage: string) => JSX.Element;
};

const MaxShotsInput = ({
  translate,
  register,
  styleInput,
  errors,
  helperText,
  isVisible
}: MaxShotsInputProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
      <Form.Label>{translate("screens.quiz.maxShotLabel")} *</Form.Label>
      <Form.Control
        type="number"
        {...register("maxShots", { required: true })}
        style={styleInput}
      />
      {errors && helperText(errors.message || "")}
    </Form.Group>
  );
};

export default MaxShotsInput;
