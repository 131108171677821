import { ReactNode } from "react";
import styled from "styled-components";
interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const StyledContainer = styled.div`
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Courier New", Courier, monospace;
  background: ${({ theme }) => theme.background};
`;

const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

export default Container;
