import { useEffect, useState } from "react";

import Loading from "app/pages/home/components/Loading";
import useQuizFormModel from "./hooks/useQuizFormNewModel";
import { QuestionsList } from "../Quiz/Questions";
import QuestionForm from "./update/QuestionForm";
import { QuizFormView } from "./components";
import { LocaliseAPIService } from "app/services/Localise/localiseApi";

import { CognitiveCategoryType, QuizType } from "app/types";

type QuizNewViewProps = ReturnType<typeof useQuizFormModel>;

const localiseApiService = LocaliseAPIService.getInstance();

const QuizNewView = ({
  loading,
  translate,
  styleInput,
  register,
  errors,
  control,
  campaigns,
  quizData,
  id,
  watch,
  getQuizById,
  onCleanQuestion,
  selectedQuestion,
  openQuestionModal,
  setOpenQuestionModal,
  questions,
  setUserIds,
  campaign,
  onDelete,
  onEdit,
  userIds,
  language,
  setLanguage,
  onSubmitForm,
  selectTheTypeQuiz,
  selectedType,
  handleChangeType,
}: QuizNewViewProps) => {
  const [cognitiveCategories, setCognitiveCategories] = useState<
    CognitiveCategoryType[]
  >([]);

  useEffect(() => {
    const start = async () => {
      const data = await localiseApiService.getCategories();
      if (data) {
        setCognitiveCategories(data);
      }
    };
    start();
  }, []);

  const watchedQuizId = Number(id) || quizData?.quizId || watch("quizId");

  const handleChangeUserIds = (userIds: string) => {
    setUserIds(userIds);
  };

  const getCategoryCognitiveBy = (id: number): string => {
    if (!id) {
      return "N/A";
    }
    const cognitiveCategory = cognitiveCategories.find(
      category => category.categoryCognitiveQuestionId === id,
    );
    return cognitiveCategory?.name || "N/A";
  };

  return (
    <div style={{ padding: "10px" }}>
      <Loading isLoading={loading} />
      <QuizFormView
        translate={translate}
        styleInput={styleInput}
        register={register}
        errors={errors}
        control={control}
        campaigns={campaigns}
        setUserIds={handleChangeUserIds}
        userIds={userIds}
        language={language}
        setLanguage={setLanguage}
        selectTheTypeQuiz={selectTheTypeQuiz}
        selectedType={selectedType}
        handleChangeType={handleChangeType}
        onSubmitForm={onSubmitForm}
        campaign={campaign}
        watch={watch}
        id={id}
      />
      {watchedQuizId ? (
        <QuestionsList
          collections={campaign.collection}
          questions={questions}
          quiz={quizData || ({} as QuizType)}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={() => setOpenQuestionModal(true)}
          onRefresh={() => {
            getQuizById(watchedQuizId);
            onCleanQuestion();
          }}
          getCategoryCognitiveBy={getCategoryCognitiveBy}
        />
      ) : null}

      <QuestionForm
        collections={campaign.collection}
        handleClose={() => setOpenQuestionModal(false)}
        open={openQuestionModal}
        question={selectedQuestion}
        onFinish={quizId => {
          getQuizById(quizId);
          onCleanQuestion();
        }}
        quizId={watchedQuizId}
        quiz={quizData || ({} as QuizType)}
        cognitiveCategories={cognitiveCategories}
      />
    </div>
  );
};

export default QuizNewView;
