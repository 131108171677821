import React from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";

import FormActions from "../../components/FormActions";
import IsValidName from "../../../../utils/validators/IsValidName";
import { QuizApiServiceImpl } from "../../../../services/Ananse/impl/QuizApiService";
import Loading from "../../../home/components/Loading";
import SelectSearchComponent from "../../../auth/Components/Select2";
import SelectLanguage from "../../components/SelectLanguage";
import MultipleSelectCheckmarks from "./component/MultipleSelectCheckmarks";
import { TranslationContext } from "_metronic/i18n/language";
import { Wrapper } from "../Users/components/Wrapper";
import { FormInput } from "../Users/components/FormInput";

const initialState = {
  name: "",
  campaignId: null,
  campaignName: "",
  languageName: "",
  resetInput: false,
  maxShots: 0,
  userId: "",
  nome: "",
  id: null,
  type: "2",
  mandatoryAnamnese: false
};

class AnamneseForm extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      translate: null,
      ...initialState,
      language: this.convertLanguage()
    };

    this.api = new QuizApiServiceImpl();

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.submit = this.submit.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  submit = async e => {
    await this.initializeTranslation();
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const reqBase = { method: "POST" };
      const {
        name,
        campaignId,
        language,
        maxShots,
        userId,
        type,
        mandatoryAnamnese
      } = this.state;

      const result = await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          name,
          campaignId,
          language,
          maxShots,
          userId,
          type,
          mandatoryAnamnese
        }
      });

      this.afterSubmit(result);
    } catch (e) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.create"),
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async result => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    this.props.history.push("/admin/quiz/" + result.quizId + "/update");
  };

  handleChangeLanguage = e => {
    this.setState({ language: e.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeSelect = e => {
    this.setState({
      campaignId: e.id,
      campaignName: e.label,
      resetInput: false
    });
  };

  handleChangeSelectMulti = e => {
    console.log("handle usuario: ", e);
    this.setState({
      userId: e
    });
  };

  formIsValid = () => {
    const { name, campaignId } = this.state;
    const isValid = IsValidName(name) && campaignId !== null;
    return isValid;
  };

  onCleanForm = async () => {
    this.setState({
      ...initialState,
      resetInput: true,
      resetInputFilial: true
    });
  };

  convertLanguage() {
    let { lang } = this.props;

    switch (lang) {
      case "pt":
        return "pt-BR";
      case "en":
        return "en-US";
      case "es":
        return "es-ES";
      default:
        return "en-US";
    }
  }

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate("screens.quiz.pageTitle.create")} - ${translate(
        "labels_admin"
      )}`
    );

    const { name, resetInput } = this.state;

    return (
      <Wrapper
        className="card-form"
        headerTitle={translate("screens_testAnamnese_register")}
      >
        <Loading isLoading={this.state.isLoading} />

        <div className="card-body">
          <form onSubmit={this.submit}>
            <Row>
              <FormInput
                label={translate("screens.fragrance.labels.name")}
                name="name"
                placeholder={translate("screens.fragrance.placeholders.name")}
                value={name}
                onChange={this.handleChange}
                required
                errorMessage={translate("screens.login.validations.invalid")}
                isInvalid={name && !IsValidName(name)}
                className="col-lg-4"
              />

              <div className="col-lg-4 col-12">
                <MultipleSelectCheckmarks
                  placeholder={translate("screens.quiz.userLabel")}
                  handleMultiSelect={this.handleChangeSelectMulti}
                />
              </div>

              <div className="col-lg-4 col-12">
                <div className="form-label">
                  {translate("screens.quiz.labels.campaign")} *
                </div>
                <SelectSearchComponent
                  resetInput={resetInput}
                  required={true}
                  handleChange={e => this.handleChangeSelect(e)}
                  className="kt-width-full"
                  label={translate("screens.quiz.placeholders.campaign")}
                  url={`/campaigns/without-quiz?str=`}
                  convertObject={obj => ({
                    id: obj.campaignId,
                    value: obj.campaignId,
                    label: `${obj.name}`,
                    obj
                  })}
                  translate={translate}
                />
              </div>

              <div className="col-lg-4 col-12">
                <div className="form-label">
                  {translate("screens.quiz.labels.language")}
                </div>
                <SelectLanguage
                  handleChangeLanguage={this.handleChangeLanguage}
                  languageValue={this.state.language}
                />
              </div>
            </Row>

            <FormActions
              module="anamnese"
              isEdit={false}
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
              submitLabel={translate("buttons.save")}
            />
          </form>
        </div>
      </Wrapper>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(AnamneseForm);
