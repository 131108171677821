import Loading from "app/pages/home/components/Loading";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "_metronic/i18n/language";
import { useForm } from "react-hook-form";
import { CognitiveAnswerType } from "app/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnanseApiService } from "app/services";
import Swal from "sweetalert2";
import { CognitiveAnswerDetail } from "interfaces";
import { Typography } from "@mui/material";
import { Box, Button, Card, Form } from "app/pages/admin/components";
import Paragraph from "app/pages/admin/components/Paragraph";

const schema = yup.object().shape({
  id: yup.number().required(),
  // questionId: yup.number().required(),
  answer: yup.string().required("Resposta é obrigatória"),
  // accountId: yup.number().required(),
  // typeQuiz: yup.string().required(),
  // quizId: yup.number().required(),
  // question: yup.string().required(),
  // endDateTime: yup.string().required(),
  // initDate: yup.string().required(),
  // userId: yup.number().required(),
  // location: yup.string().required(),
  // professionalId: yup.string().required(),
  // doctor: yup.string().required(),
  // email: yup
  //   .string()
  //   .email("E-mail inválido")
  //   .required(),
  // testResultId: yup.string().required(),
  // midiaUri: yup.string().required(),
  // createdAt: yup.string().required(),
  // updatedAt: yup.string().required(),
  // deletedAt: yup.string().required(),
});

const CognitiveAnswerFormNew: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const translate = useTranslation();
  const api = new AnanseApiService();
  const location = useLocation<any>();
  const history = useHistory();
  const state = location.state;

  // const [cc, setCc] = useState("contato@noar.health");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<CognitiveAnswerType>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: undefined,
      questionId: undefined,
      answer: "",
      accountId: undefined,
      typeQuiz: "",
      quizId: 0,
      question: "",
      endDateTime: "",
      initDate: "",
      userId: undefined,
      location: "",
      professionalId: "",
      doctor: "",
      email: "",
      testResultId: "",
      midiaUri: "",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: "",
    },
  });

  useEffect(() => {
    const buscaDadosAnswer = async () => {
      setIsLoading(true);

      if (state?.cognitiveAnswerDetail) {
        const cognitiveAnswerDetail: CognitiveAnswerDetail =
          state.cognitiveAnswerDetail;

        setValue("accountId", cognitiveAnswerDetail.accountId);
        setValue("answer", cognitiveAnswerDetail.answer);
        setValue("createdAt", cognitiveAnswerDetail.createdAt);
        setValue("deletedAt", cognitiveAnswerDetail.deletedAt);
        setValue("doctor", cognitiveAnswerDetail.doctor);
        setValue("email", cognitiveAnswerDetail.email);
        setValue("endDateTime", cognitiveAnswerDetail.endDateTime);
        setValue("id", cognitiveAnswerDetail.id);
        setValue("initDate", cognitiveAnswerDetail.initDate);
        setValue("location", cognitiveAnswerDetail.location);
        setValue("midiaUri", cognitiveAnswerDetail.midiaUri);
        setValue("professionalId", cognitiveAnswerDetail.professionalId);
        setValue("question", cognitiveAnswerDetail.question);
        setValue("questionId", cognitiveAnswerDetail.questionId);
        setValue("quizId", cognitiveAnswerDetail.quizId);
        setValue("testResultId", cognitiveAnswerDetail.testResultId);
        setValue("typeQuiz", cognitiveAnswerDetail.typeQuiz);
        setValue("updatedAt", cognitiveAnswerDetail.updatedAt);
        setValue("userId", cognitiveAnswerDetail.userId);
      } else {
        showMensage(false, "O ID deve ser um número válido.");
        history.push(`/admin/cognitive`);
      }

      setIsLoading(false);
    };

    buscaDadosAnswer();
  }, []);

  const helperText = (text: string) => (
    <Typography color={"red"}>{text}</Typography>
  );

  const showMensage = (sucess: any, error: any) => {
    if (error) {
      Swal.fire(
        translate("screenApp_resetFirmwareComponents_titleErro"),
        error ? error : translate("labels_errorEmail"),
        "error",
      );
    } else if (sucess) {
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: translate(sucess),
        // text: translate("labels.emailSuccess"),
        icon: "success",
        showCancelButton: false,
        confirmButtonText: translate("buttons.ok"),
        cancelButtonText: translate("buttons.exit"),
      });
    }
  };

  const onSubmit = async (data: CognitiveAnswerType) => {
    try {
      setIsLoading(true);
      if (data) {
        const reqBase = {
          method: "PUT",
          url: `/quizanswercognitive/${data.id}`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        const prepareData: CognitiveAnswerType = { ...data };

        const response = await api.makeHttpRequest({
          ...reqBase,
          data: JSON.stringify({ ...prepareData }),
        });

        if (response) {
          // setValue("answer", "");
          showMensage(response.answer, null);
          history.push(`/admin/cognitive`);
        }
      }
    } catch (error) {
      if ((error as any).response.data) {
        showMensage(false, (error as any).response.data);
      } else {
        showMensage(false, "Falha de comunicação com a Base de Dados."); // translate("")
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <Loading isLoading={isLoading} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card
          style={{
            width: "95%",
          }}
        >
          <Card.Title>
            {/* {translate("")} */}
            Resposta de pergunta do teste Cognitivo
          </Card.Title>
          <Card.Body
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Box
              style={{
                padding: "20px 10px",
              }}
            >
              <Paragraph>
                <Paragraph.Bold>
                  {/* {translate("")} */}
                  Pergunta:
                </Paragraph.Bold>
                {watch("question")}
              </Paragraph>
            </Box>
            <Box
              style={{
                padding: "20px 10px",
              }}
            >
              <Form.Group
                style={{
                  width: "100%",
                }}
              >
                <Form.Label>
                  <Paragraph>
                    <Paragraph.Bold>
                      {/* {translate("")} */}
                      Reposta:
                    </Paragraph.Bold>
                  </Paragraph>
                </Form.Label>
                <Form.Input
                  style={{ maxWidth: "500px" }}
                  {...register("answer", { required: true })}
                  value={watch("answer") || ""}
                />
                {errors.answer && helperText(errors.answer?.message || "")}
              </Form.Group>
            </Box>
          </Card.Body>
          <Card.Actions
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "end",
              gap: "15px",
            }}
          >
            <Link to={"/admin/cognitive"}>
              <Button type="button" variant="error">
                {translate("buttons_cancel")}
              </Button>
            </Link>
            <Button type="submit" variant="success">
              {translate("buttons_save")}
            </Button>
          </Card.Actions>
        </Card>
      </Form>

      {/* <FormAnswer id={Number(id)} /> */}
    </div>
  );
};

export default CognitiveAnswerFormNew;
