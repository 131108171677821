import React from "react";

import ModalAddImageAnswer from "../components/ModalAddImageAnswer";
import Loading from "app/pages/home/components/Loading";
import { useQuestionForm } from "./useQuestionForm";
import ModalDialogView from "./components/ModalDialogView";

import {
  CognitiveCategoryType,
  CollectionType,
  QuestionType,
  QuizType,
} from "app/types";

type QuestionFormModalProps = {
  collections: CollectionType[];
  open: boolean;
  quiz: QuizType;
  quizId: number;
  question?: QuestionType;
  cognitiveCategories: Array<CognitiveCategoryType>;
  handleClose: () => void;
  onFinish: (quizId: number) => void;
};

const QuestionForm: React.FC<QuestionFormModalProps> = ({
  collections,
  open,
  question,
  quiz,
  quizId,
  onFinish,
  handleClose,
  cognitiveCategories,
}) => {
  const {
    styleInput,
    translate,
    cognitiveInputs,
    showModalAnswersImage,
    sequence,
    selectedType,
    remove,
    register,
    preViewImage,
    onSubmit,
    onClose,
    handleQuestChange,
    handleFileQuestion,
    handleDescChange,
    handleClearQuest,
    fields,
    errors,
    control,
    clearImageQuestion,
    append,
    answers,
    modalAnswersImage,
    answersOld,
    loading,
  } = useQuestionForm({
    handleClose,
    onFinish: () => onFinish(quizId),
    quiz,
    question,
    open,
  });

  return (
    <>
      <Loading isLoading={loading} />
      <ModalAddImageAnswer
        answers={answers}
        answersOld={answersOld}
        open={modalAnswersImage}
        onClose={onClose}
      />
      <ModalDialogView
        answers={answers}
        append={append}
        clearImageQuestion={clearImageQuestion}
        cognitiveInputs={cognitiveInputs}
        collections={collections}
        control={control}
        errors={errors}
        fields={fields}
        handleClearQuest={handleClearQuest}
        handleDescChange={handleDescChange}
        handleFileQuestion={handleFileQuestion}
        handleQuestChange={handleQuestChange}
        onClose={onClose}
        onSubmit={onSubmit}
        open={open}
        preViewImage={preViewImage}
        register={register}
        quiz={quiz}
        remove={remove}
        selectedType={selectedType}
        sequence={sequence}
        showModalAnswersImage={showModalAnswersImage}
        styleInput={styleInput}
        translate={translate}
        cognitiveCategories={cognitiveCategories}
      />
    </>
  );
};

export default QuestionForm;
