import { useEffect, useState } from "react";
import { useTranslation } from "_metronic/i18n/language";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import { AnswerType, FileType, QuestionType, QuizType } from "app/types";
import { QUIZ_TYPES_CONTANTS } from "app/types/quiz-constants";
import { selectInput, SelectInputType } from "mocks/quizInputs";
import { defaultValues } from "./update-data";
import Swal from "sweetalert2";
import { addUpdate } from "app/services/uploadFileToAzureBlob";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./update-schema";
import { QuestionFormType } from "./update-type";

type QuestionFormModelType = {
  quiz: QuizType;
  question?: QuestionType;
  handleClose: () => void;
  onFinish: () => void;
  open?: boolean;
};

export const useQuestionForm = ({
  quiz,
  handleClose,
  onFinish,
  question,
  open = false
}: QuestionFormModelType) => {
  const [loading, setLoading] = useState(false);
  const [modalAnswersImage, setModalAnswersImage] = useState(false);
  const [answersOld, setAnswersOld] = useState<AnswerType[]>([]);
  const [imageQuestion, setImageQuestion] = useState<FileType>({} as FileType);
  const [preViewImage, setPreViewImage] = useState("");

  const { isDarkMode } = useThemeApp();
  const translate = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm<QuestionFormType>({
    resolver: yupResolver(schema),
    defaultValues
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "answers"
  });
  const questions = quiz.questions || [];
  const styleInput = {
    backgroundColor: isDarkMode ? "transparent" : "#fff",
    color: isDarkMode ? "#ffffff" : "#747a91"
  };

  useEffect(() => {
    setValue("quizTypeKey", quiz.type);
    if (question) {
      setValue("quizId", question.quizId || quiz.quizId);
      setValue("quizQuestionId", question.quizQuestionId);
      setValue("questionId", question.questionId);
      setValue("quest", question?.question?.quest || "");
      setValue("description", question.question?.description || "");
      setValue("fragranceId", question.fragranceId);
      setValue("rightAnswer", question.rightAnswer);
      setValue("instruction", question?.instruction || "");
      if (question.files?.length && question.files[0]?.tempUri) {
        setImageQuestion(question.files?.length ? question.files[0] : {});
        setPreViewImage(question?.files[0]?.tempUri);
      }
      setValue("type", question.type);
      setValue(
        "categoryCognitiveQuestionId",
        question?.categoryCognitiveQuestionId || ""
      );
      setValue("arrangeQuestionSequence", question.arrangeQuestionSequence);
      setValue("timeInSeconds", question.timeInSeconds);
      setAnswersOld(question.answers);
      replace(
        question.answers.map(answer => ({
          quizQuestionAnswerId: answer.quizQuestionAnswerId,
          answerId: answer.answerId,
          desc: answer.desc || answer.answer?.label || "",
          description: answer.description || answer.answer?.description || ""
        }))
      );
    } else {
      setValue("arrangeQuestionSequence", questions.length + 1);
    }
  }, [question, setValue, replace, open, quiz.quizId]);

  const onClose = () => {
    handleClose();
    setModalAnswersImage(false);
    reset(defaultValues);
    onFinish();
  };

  const onSubmit = async (data: QuestionType): Promise<void> => {
    setLoading(true);
    try {
      const newData = {
        ...data,
        quizId: data.quizId || question?.quizId || quiz.quizId,
        file: imageQuestion,
        quizTypeKey: undefined
      };
      delete newData.quizTypeKey;
      await addUpdate(newData);
      const message = data.quizQuestionId
        ? translate("registration_updated_successfully")
        : translate("crudMessages_successText");

      onClose();
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: message,
        icon: "success"
      }).then(() => {
        if (data.type === "image") {
          setModalAnswersImage(true);
        }
      });
    } catch (error) {
      handleClose();
      Swal.fire({
        title: translate("screenApp_capsula_error"),
        text: "Não foi possivel add ou atualiza perguta",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileQuestion = (event: { target: any }) => {
    const file: File | undefined = event?.target.files?.[0];
    if (file) {
      const prepareData: FileType = {
        file,
        name: file.name
      };
      setImageQuestion(prepareData); // Envia a imagem para o setImageQuestion
      setPreViewImage(URL.createObjectURL(file)); // Cria o preview da imagem
    }
  };

  const handleClearQuest = () => {
    setValue("quest", "");
    setValue("questionId", 0);
  };

  const handleQuestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("quest", e.target.value);
    if (!e.target.value) setValue("questionId", 0);
  };

  const handleDescChange = (index: number, value: string): void => {
    setValue(`answers.${index}.desc`, value);
    if (value === "") {
      setValue(`answers.${index}.answerId`, 0);
    }
  };

  const clearImageQuestion = () => {
    reset(defaultValues), setImageQuestion({} as FileType), setPreViewImage("");
  };

  const showModalAnswersImage = () => setModalAnswersImage(true);

  function getCognitiveInputs(quizType: number) {
    return selectInput.filter(value => {
      const isCognitiveQuiz = quizType === QUIZ_TYPES_CONTANTS.COGNITIVE;
      const excludedTypes = ["number", "voice", "speechtotext", "drawing"];
      if (!isCognitiveQuiz && excludedTypes.includes(value.type)) {
        return false;
      }
      return true;
    });
  }

  return {
    cognitiveInputs: getCognitiveInputs(+quiz.type),
    sequence: watch("arrangeQuestionSequence") || 0,
    selectedType: watch("type") as SelectInputType,
    onSubmit: handleSubmit(onSubmit),
    answers: watch("answers"),
    styleInput,
    translate,
    showModalAnswersImage,
    remove,
    register,
    preViewImage,
    onClose,
    handleQuestChange,
    handleFileQuestion,
    handleDescChange,
    handleClearQuest,
    fields,
    errors,
    control,
    clearImageQuestion,
    append,
    modalAnswersImage,
    answersOld,
    loading
  };
};
