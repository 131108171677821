export const selectTypeQuiz = [
  { key: 1, name: "Indentificação" },
  // { key: 2, name: "Anamnese" }, // não descomentar, pois é usado no formulario de Anamnese
  { key: 3, name: "Discriminação" }
];

export const selectInput = [
  {
    key: 1,
    type: "slider",
    name: "Slider"
  },
  {
    key: 2,
    type: "checkbox",
    name: "CheckBox"
  },
  {
    key: 3,
    type: "select",
    name: "Select"
  },
  {
    key: 4,
    type: "radio",
    name: "Radio"
  },
  {
    key: 5,
    type: "text",
    name: "Text"
  },
  {
    key: 6,
    type: "image",
    name: "Image"
  },
  {
    key: 7,
    type: "speechtotext",
    name: "Speech to Text"
  },
  {
    key: 8,
    type: "voice",
    name: "Voice"
  },
  {
    key: 9,
    type: "drawing",
    name: "Drawing"
  },
  {
    key: 10,
    type: "number",
    name: "Number"
  },
  {
    key: 11,
    type: "speak-speechtotext",
    name: "Reproduzir e gravar"
  },
] as const;

export type SelectInputType = typeof selectInput[number]["type"];

export function formatedTypeInput(key: string): string | undefined {
  const keyInt: number = parseInt(key, 10);
  const find = selectInput.find(v => v.key === keyInt);
  return find?.name;
}
