import { QuestionService } from "../QuestionService";
import { CRUDApiService } from "./CRUDApiService";

export class QuestionApiServiceImpl extends CRUDApiService
  implements QuestionService {
  constructor() {
    super("question");
  }

  getFileByFileOwnerId(id: number | string): Promise<any> {
    if (!id) {
      throw new Error("id should be provided");
    }

    return this.makeHttpRequest({
      method: "GET",
      url: `/by-fileownerid?fileOwnerId=${id}`
    });
  }
}
