import { Box, IconButton, InputAdornment } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister
} from "react-hook-form";
import { Clear } from "@mui/icons-material";

import { QUIZ_TYPES_CONTANTS } from "app/types/quiz-constants";
import BackgroundImageInput from "./BackgroundImageInput";
import InstructionInput from "./InstructionInput";
import { SelectInputType } from "mocks/quizInputs";
import {
  CognitiveCategoryType,
  CollectionType,
  QuestionType,
  QuizType
} from "app/types";
import { QuestionFormType } from "../update-type";

type ContentQuestionFormViewProps = {
  cognitiveCategories: Array<CognitiveCategoryType>;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  control: Control<QuestionFormType, any>;
  quiz: QuizType;
  selectedType: SelectInputType;
  preViewImage: string;
  errors: FieldErrors<QuestionType>;
  collections: Array<CollectionType>;
  cognitiveInputs: Array<{ type: string; name: string }>;
  translate: (key: string) => string;
  register: UseFormRegister<QuestionFormType>;
  handleFileQuestion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText: (text?: string) => JSX.Element;
  handleClearQuest: () => void;
  handleQuestChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ContentQuestionFormView = ({
  cognitiveCategories,
  translate,
  styleInput,
  control,
  quiz,
  register,
  handleFileQuestion,
  selectedType,
  preViewImage,
  errors,
  helperText,
  collections,
  cognitiveInputs,
  handleClearQuest,
  handleQuestChange
}: ContentQuestionFormViewProps) => {
  return (
    <Row>
      <Form.Group as={Col} lg="12" xs="12" className="mb-0 pt-2">
        <Form.Label>{translate("screens.quiz.labels.question")} *</Form.Label>
        <Box display="flex" alignItems="center">
          <Form.Control
            placeholder={translate("screens.quiz.labels.question")}
            {...register("quest")}
            onChange={handleQuestChange}
            as="textarea"
            rows={1}
            style={styleInput}
          />
          <InputAdornment position="end">
            <IconButton
              title={translate("buttons_clean")}
              onClick={handleClearQuest}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        </Box>
        {errors.quest && helperText(errors.quest.message)}
      </Form.Group>
      <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
        <Form.Label>{translate("screenApp.quiz.questionType")} *</Form.Label>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Form.Select {...field} style={styleInput}>
              <option value="">
                {translate("screenApp_quiz_selectOption")}
              </option>
              {cognitiveInputs.map((value, index) => (
                <option value={value.type} key={index}>
                  {value.name}
                </option>
              ))}
            </Form.Select>
          )}
        />
        {errors.type && helperText(errors.type.message)}
      </Form.Group>
      {quiz.type !== '7' && selectedType !== "radio" && (
        <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
          <Form.Label>
            {translate("screens.fragrance.questionReferenceFragrance")}
          </Form.Label>
          <Controller
            control={control}
            name="fragranceId"
            render={({ field }) => (
              <Form.Select {...field} style={styleInput}>
                <option value="">
                  {translate("screens.quiz.placeholders.fragrances")}
                </option>
                {collections
                  .sort((a, b) => a.slot - b.slot)
                  .map(collection => (
                    <option
                      key={collection.fragrance.fragranceId}
                      value={collection.fragrance.fragranceId}
                    >
                      {`${collection.slot} - ${collection.fragrance.name}`}
                    </option>
                  ))}
              </Form.Select>
            )}
          />
          {errors.fragranceId && helperText(errors.fragranceId.message)}
        </Form.Group>
      )}
      <InstructionInput
        register={register("instruction")}
        styleInput={styleInput}
        selectedType={selectedType}
      />
      <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
        <Form.Label>Descricao</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          {...register("description")}
          style={styleInput}
        />
      </Form.Group>
      <BackgroundImageInput
        previewImage={preViewImage}
        label={
          selectedType === "speechtotext" &&
          [
            QUIZ_TYPES_CONTANTS.CHILDHOOD_IDENTIFICATION.toString(),
            QUIZ_TYPES_CONTANTS.COGNITIVE.toString()
          ].includes(quiz.type)
            ? "Imagem da pergunta"
            : "Imagem de fundo da pergunta"
        }
        style={styleInput}
        onChange={handleFileQuestion}
      />
      {QUIZ_TYPES_CONTANTS.COGNITIVE === +quiz.type && (
        <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
          <Form.Label>Tempo (em segundos)</Form.Label>
          <Form.Control
            as="input"
            type="number"
            step={30}
            defaultValue={0}
            min={0}
            {...register("timeInSeconds")}
            style={styleInput}
          />
        </Form.Group>
      )}
      {QUIZ_TYPES_CONTANTS.COGNITIVE === +quiz.type &&
        cognitiveCategories.length && (
          <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
            <Form.Label>Categoria Cognitiva</Form.Label>
            <Controller
              name="categoryCognitiveQuestionId"
              control={control}
              render={({ field }) => (
                <Form.Select {...field} style={styleInput}>
                  <option value="">
                    {translate("screenApp_quiz_selectOption")}
                  </option>
                  {cognitiveCategories.map(value => (
                    <option
                      value={value.categoryCognitiveQuestionId}
                      key={value.name}
                    >
                      {value.name}
                    </option>
                  ))}
                </Form.Select>
              )}
            />
          </Form.Group>
        )}
    </Row>
  );
};

export default ContentQuestionFormView;
