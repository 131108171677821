import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Sort } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import Loading from "app/pages/home/components/Loading";
import { QuestionApiServiceImpl } from "app/services/Ananse/impl/QuestionApiService";
import { QuestionType } from "app/types";
import React, { useEffect, useState } from "react";
import { SortableItem } from "./SortableItem";

interface Props {
  questions: QuestionType[];
  onSave: (updatedQuestions: QuestionType[]) => void;
}

const ArrangeSequenceModal: React.FC<Props> = ({ questions, onSave }) => {
  const translate = useTranslation();
  const [localQuestions, setLocalQuestions] = useState<QuestionType[]>(
    questions
  );
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const questionService = new QuestionApiServiceImpl();

  useEffect(() => {
    setLocalQuestions(questions);
  }, [questions]);

  const handleClose = () => setShowModal(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = localQuestions.findIndex(
        item => item.quizQuestionId === active.id
      );
      const newIndex = localQuestions.findIndex(
        item => item.quizQuestionId === over.id
      );

      const updatedQuestions = arrayMove(localQuestions, oldIndex, newIndex);

      updatedQuestions.forEach((question, index) => {
        question.arrangeQuestionSequence = index + 1;
      });

      setLocalQuestions(updatedQuestions);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const totalQuestions = localQuestions.length;
    try {
      for (let i = 0; i < localQuestions.length; i++) {
        const question = localQuestions[i];
        const req = {
          method: "PUT",
          url: `/update-sequence/${question.quizQuestionId}/${question.arrangeQuestionSequence}`
        };
        await questionService.makeHttpRequest(req);

        setProgress(Math.round(((i + 1) / totalQuestions) * 100));
      }
    } catch (error) {
      console.error(error);
    } finally {
      onSave(localQuestions);
      handleClose();
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <>
      <Loading isLoading={loading} progress={progress} />
      <IconButton
        title={translate("labels_arrangeQuestionSequence")}
        onClick={() => setShowModal(true)}
      >
        <Sort />
      </IconButton>
      <Dialog open={showModal} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{translate("labels_arrangeQuestionSequence")}</DialogTitle>

        <DialogContent>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={localQuestions.map(item => item.quizQuestionId)}
              strategy={verticalListSortingStrategy}
            >
              {localQuestions
                .sort(
                  (a, b) =>
                    a?.arrangeQuestionSequence! - b?.arrangeQuestionSequence!
                )
                .map((question, index) => (
                  <SortableItem
                    key={question.quizQuestionId}
                    id={question.quizQuestionId}
                  >
                    {`${question.arrangeQuestionSequence}: ${question.quest ||
                      question?.question?.quest}`}
                  </SortableItem>
                ))}
            </SortableContext>
          </DndContext>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            {translate("buttons_cancel")}
          </Button>
          <Button color="success" variant="contained" onClick={handleSave}>
            {translate("buttons_save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArrangeSequenceModal;
