import clsx from "clsx";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from "@mui/material";

import { MonthlyTestResults } from "components";
import { useTranslation } from "_metronic/i18n/language";
import { useDashDsa } from "../../context/DashdsaContext";
import {
  getTranslatedMonths,
  transformData
} from "../../utils/monthDataTransformer";
import { DataDashType, DataTest } from "../../dsa-type";
import { LabelsContants } from "app/helpers/constants";

type TestByMonthChartProps = {
  testNamesList: Array<string>;
  getTestDataByYear: () => DataTest | undefined;
  getValuesByTestNames: (
    testName: Array<string>,
    year: number
  ) => Array<DataDashType>;
  className?: string;
};

export default function TestByMonthChart({
  className,
  testNamesList,
  getTestDataByYear,
  getValuesByTestNames
}: TestByMonthChartProps): JSX.Element | null {
  const {
    selectedYear,
    handleChangeYear,
    selectedTestNames,
    handleTestNamesChange: setSelectedTestNames
  } = useDashDsa();

  const data = getValuesByTestNames(
    selectedTestNames.filter(value => value !== LabelsContants.ALL),
    +selectedYear
  );
  const translate = useTranslation();
  const label = translate("labels_dashboard_selectYear");

  if (!data || data.length === 0 || Object.keys(data).length === 0) {
    return null;
  }

  const years = Object.keys(getTestDataByYear() || {}).sort(
    (a: string, b: string) => +b - +a
  );
  const translatedMonths = getTranslatedMonths(translate);
  const chartData = transformData(
    data,
    translatedMonths,
    selectedYear.toString()
  );

  const handleChange = ({
    target
  }: SelectChangeEvent<typeof selectedTestNames>): void => {
    const { value } = target;
    setSelectedTestNames(typeof value === "string" ? value.split(",") : value);
  };

  function renderFilter(): JSX.Element | null {
    return (
      <div className="bar-graph-filters">
        <FormControl className="multiple-test-filter" sx={{ m: 1 }}>
          <InputLabel id="multiple-checkbox-label">Test</InputLabel>
          <Select
            labelId="multiple-checkbox-label"
            id="multiple-checkbox"
            className="multiple-checkbox-select"
            multiple
            value={selectedTestNames}
            onChange={handleChange}
            input={<OutlinedInput label="Test" />}
            renderValue={selected => selected.join(", ")}
            style={{ height: 46 }}
          >
            <MenuItem key={"key-0"} value={LabelsContants.ALL}>
              <Checkbox
                checked={testNamesList.length === selectedTestNames.length}
                className="test-name-checkox"
                color="info"
                name="all"
                onChange={({ target }) => {
                  if (target.checked) {
                    setSelectedTestNames(testNamesList);
                  }
                  if (!target.checked) {
                    setSelectedTestNames([]);
                  }
                }}
              />
              <ListItemText primary={LabelsContants.ALL} />
            </MenuItem>
            {testNamesList.map(name => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={selectedTestNames.includes(name)}
                  className="test-name-checkox"
                  color="info"
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {years.length > 0 && (
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            className="year-filter"
          >
            <InputLabel htmlFor="yearSelect">{label} </InputLabel>
            <Select
              id="yearSelect"
              value={selectedYear}
              label={label}
              onChange={e => handleChangeYear(+e.target.value)}
            >
              {years.map(year => (
                <MenuItem
                  key={year}
                  selected={+year === selectedYear}
                  value={year}
                >
                  <ListItemText>{year}</ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    );
  }

  return (
    <div className={clsx("c-test-month-container", className)}>
      <MonthlyTestResults
        title={translate("labels_dashboard_testsMonth")}
        data={chartData}
        actionFilterComponent={renderFilter()}
      />
    </div>
  );
}
