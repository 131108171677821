import { Form, Row, Col } from "react-bootstrap";
import { CardContent, Stack, Typography } from "@mui/material";
import DialogSelectUsers from "../../Quiz/component/DialogSelectUsers";
import SelectComponent from "../../Quiz/component/Select";
import MaxShotsInput from "./MaxShotsInput";
import SelectLanguage from "app/pages/admin/components/SelectLanguage";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { hideInputs } from "../quiznew-data";
import { CampaignType, QuizType } from "app/types";

type CardContentViewProps = {
  errors: FieldErrors<QuizType>;
  userIds: string;
  setUserIds: (userIds: string) => void;
  translate: (text: string) => string;
  register: UseFormRegister<QuizType>;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  watch: UseFormWatch<QuizType>;
  selectedType: number;
  selectTheTypeQuiz: Array<{
    key: number;
    name: string;
  }>;
  handleChangeType: (type: number) => void;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  campaigns: Array<CampaignType>;
  control: Control<QuizType, any>;
};

type HideInputsType = typeof hideInputs;
type HideInputsKeysType = keyof HideInputsType;

const CardContentView = ({
  errors,
  userIds,
  setUserIds,
  translate,
  register,
  styleInput,
  watch,
  selectedType,
  selectTheTypeQuiz,
  handleChangeType,
  language,
  setLanguage,
  campaigns,
  control,
}: CardContentViewProps) => {
  const showInput = (input: HideInputsKeysType): boolean => {
    return !hideInputs[input].includes(selectedType);
  };

  const helperText = (text: string) => (
    <Typography color={"red"}>{text}</Typography>
  );

  return (
    <CardContent>
      <Row>
        <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
          <Form.Label>{translate("screens.quiz.labels.name")} *</Form.Label>
          <Form.Control
            placeholder={translate("screens.quiz.labels.name")}
            style={styleInput}
            {...register("name", { required: true })}
          />
          {errors.name && helperText(errors.name?.message || "")}
        </Form.Group>
        <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
          <Form.Label>{translate("screens.quiz.labels.campaign")} *</Form.Label>
          <Controller
            name="campaignId"
            control={control}
            render={({ field }) => (
              <Form.Select
                placeholder={translate("screens.quiz.labels.campaign")}
                value={field.value}
                onChange={e => {
                  field.onChange(Number(e.target.value));
                }}
                style={styleInput}
              >
                <option value="" style={styleInput}>
                  {translate("screens.quiz.placeholders.campaign")}
                </option>
                {campaigns.map((campaign, index) => (
                  <option
                    key={`index${index}-${campaign.campaignId}`}
                    value={campaign.campaignId.toString()}
                    style={styleInput}
                  >
                    {campaign.name}
                  </option>
                ))}
              </Form.Select>
            )}
          />
          {errors.campaignId && helperText(errors.campaignId?.message || "")}
        </Form.Group>
        <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
          <Form.Label>{translate("screens.quiz.labels.language")}</Form.Label>
          <SelectLanguage
            handleChangeLanguage={(lang: any) => setLanguage(lang.value)}
            languageValue={language}
          />
          {errors.language && helperText(errors.language.message || "")}
        </Form.Group>
        <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
          <Form.Label>
            {translate("screens.fragrance.labels.type")} *
          </Form.Label>
          <Form.Select
            {...register("type", { required: true })}
            style={styleInput}
            onChange={event => handleChangeType(+event.target.value)}
          >
            <option value={""}>
              {translate("screens_quiz_selectTest_title")}
            </option>
            {selectTheTypeQuiz
              .filter(value => value.key !== 2)
              .map(value => (
                <option key={value.key} value={value.key}>
                  {value.name}
                </option>
              ))}
          </Form.Select>
          {errors.type && helperText(errors.type.message || "")}
        </Form.Group>
        <MaxShotsInput
          errors={errors?.maxShots}
          helperText={helperText}
          isVisible={showInput("maxShots")}
          register={register}
          styleInput={styleInput}
          translate={translate}
        />
        {showInput("screening") && (
          <Form.Group
            as={Col}
            lg={watch("screeningId") ? "4" : "6"}
            xs="12"
            className="mb-0 pt-2"
          >
            <SelectComponent
              url="quiz/anamnese"
              placeholder={"Triagem"}
              register={register("screeningId")}
              convertResultData={(data: any) => ({
                value: data.quizId.toString(),
                label: data.name,
              })}
              value={watch("screeningId") || ""}
            />
          </Form.Group>
        )}
        {showInput("anamnese") && (
          <Form.Group
            as={Col}
            lg={watch("anamneseId") ? "4" : "6"}
            xs="12"
            className="mb-0 pt-2"
          >
            <SelectComponent
              url="quiz/anamnese"
              placeholder={translate("screens.quiz.labels.selectAnamnesis")}
              register={register("anamneseId")}
              defaultValue={0}
              value={watch("anamneseId")}
            />
          </Form.Group>
        )}

        {!!watch("anamneseId") && (
          <Form.Group as={Col} lg="2" xs="12" className="mb-0 pt-2">
            <Form.Label>
              {translate("screens_quiz_labels_anamnesisMandatory")}
            </Form.Label>
            <Form.Check {...register("mandatoryAnamnese")} style={styleInput} />
          </Form.Group>
        )}
        <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
          <Stack>
            <Form.Label>
              {translate("screens_quiz_users_associated")} *
            </Form.Label>
            <DialogSelectUsers userIds={userIds} setUserIds={setUserIds} />
            {errors.userId && helperText(errors.userId.message || "")}
          </Stack>
        </Form.Group>
      </Row>
    </CardContent>
  );
};

export default CardContentView;
