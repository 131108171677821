export const labelQuizType = (
  type: string,
  translate: (key: string, vars?: Record<string, string>) => string,
): string => {
  switch (type) {
    case "1":
      return translate("screenApp.listPacient.nameTest");
    case "2":
      return translate("labels_testType_two");
    case "3":
      return translate("screenApp.results.discriminationTest");
    case "4":
      return translate("labels_testType_four");
    case "5":
      return translate("labels_ratings");
    case "6":
      return translate("labels_child_identificationTest");
    case "7":
      return translate("labels_cognitive");
    default:
      return translate("labels_undefined_test");
  }
};
