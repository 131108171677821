import { ChangeEvent, CSSProperties } from "react";
import { Col, Form } from "react-bootstrap";

type BackgroundImageInputProps = {
  previewImage: string;
  label: string;
  style?: CSSProperties | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const BackgroundImageInput = ({
  previewImage,
  label,
  style,
  onChange
}: BackgroundImageInputProps) => {
  return (
    <>
      <Form.Group
        as={Col}
        lg={previewImage ? "3" : "6"}
        xs="12"
        className="mb-0 pt-2"
      >
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="file"
          onChange={onChange}
          accept=".png, .jpg, .jpeg"
          style={style}
        />
      </Form.Group>
      {previewImage && (
        <Form.Group as={Col} lg="3" xs="12" className="mb-0 pt-2">
          <div
            style={{
              marginTop: "10px",
              borderWidth: 3,
              borderColor: "#ccc"
            }}
          >
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: "auto", height: 130 }}
            />
          </div>
        </Form.Group>
      )}
    </>
  );
};

export default BackgroundImageInput;
