import React, { ReactNode, FC } from "react";
import styled from "styled-components";

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  backgroundImage?: string;
  children?: ReactNode;
  width?: string;
  height?: string;
  borderRadius?: string;
}

const StyledBox = styled.div<BoxProps>`
  position: relative;
  min-width: 400px;
  background-color: transparent;
  /* border: 2px solid rgba(255, 255, 255, 0.5); */
  border-radius: 10px;
  backdrop-filter: blur(55px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
`;

const Box: FC<BoxProps> = ({ children, ...props }) => {
  return <StyledBox {...props}>{children}</StyledBox>;
};

export default Box;
