import {
  AverageData,
  DataTest,
  RespostaDetalhada,
  Respostas,
  ShotsAverage,
  TimeAverage
} from "../dsaresult-type";

export function calcularMediaPorFaixaEtaria(data: DataTest[]): AverageData[] {
  const somatorio: { [faixa: string]: number } = {};
  const counts: { [faixa: string]: number } = {};

  data.forEach(item => {
    if (somatorio[item.FaixaEtaria]) {
      somatorio[item.FaixaEtaria] += item.Acertos;
      counts[item.FaixaEtaria] += 1;
    } else {
      somatorio[item.FaixaEtaria] = item.Acertos;
      counts[item.FaixaEtaria] = 1;
    }
  });

  const result = Object.keys(somatorio).map(faixa => ({
    faixaEtaria: faixa,
    mediaAcertos: somatorio[faixa] / counts[faixa]
  }));

  return result;
}

export const calculateAverageTimeByAnswer = (
  data: DataTest[]
): TimeAverage[] => {
  const timeSums: { [key: string]: number } = {};
  const counts: { [key: string]: number } = {};

  data.forEach(item => {
    const startTime = new Date(item.Inicio_Teste);
    const endTime = new Date(item.Fim_Teste);
    const timeDifference = (endTime.getTime() - startTime.getTime()) / 1000;

    if (timeSums[item.RightAnswer]) {
      timeSums[item.RightAnswer] += timeDifference;
      counts[item.RightAnswer] += 1;
    } else {
      timeSums[item.RightAnswer] = timeDifference;
      counts[item.RightAnswer] = 1;
    }
  });

  const averages: TimeAverage[] = Object.keys(timeSums).map(key => ({
    RightAnswer: key,
    averageTimeSeconds: timeSums[key] / counts[key]
  }));

  return averages;
};

export const calculateAverageShotsByAnswer = (
  data: DataTest[]
): ShotsAverage[] => {
  const shotsSums: { [key: string]: number } = {};
  const counts: { [key: string]: number } = {};

  data.forEach(item => {
    if (shotsSums[item.RightAnswer]) {
      shotsSums[item.RightAnswer] += item.Shots;
      counts[item.RightAnswer] += 1;
    } else {
      shotsSums[item.RightAnswer] = item.Shots;
      counts[item.RightAnswer] = 1;
    }
  });

  const averages: ShotsAverage[] = Object.keys(shotsSums).map(key => ({
    RightAnswer: key,
    averageShots: shotsSums[key] / counts[key]
  }));

  return averages;
};

export const processData = (data: DataTest[]): RespostaDetalhada[] => {
  const results: Respostas = {};

  data.forEach(item => {
    const key = item.RightAnswer;
    if (!results[key]) {
      results[key] = { Acertos: 0, Erros: 0, Total: 0 };
    }
    if (item.Resultado === "Acerto") {
      results[key].Acertos += 1;
    } else {
      results[key].Erros += 1;
    }
    results[key].Total += 1;
  });

  return Object.keys(results).map(key => ({
    RightAnswer: key,
    Acertos: results[key].Acertos,
    Erros: results[key].Erros,
    AcertosPercent: ((results[key].Acertos / results[key].Total) * 100).toFixed(
      2
    ),
    ErrosPercent: ((results[key].Erros / results[key].Total) * 100).toFixed(2)
  }));
};
