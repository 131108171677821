import React, { ReactNode, FC } from "react";
import styled from "styled-components";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled.label`
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  font-size: 16px;
  background: ${({ theme }) => theme.secondaryBackground};
  color: ${({ theme }) => theme.text};
`;

const Select = styled.select`
  padding: 10px;
  padding-right: 40px; /* Aumenta o espaço entre o texto e a seta */
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  font-size: 12px;
  background: ${({ theme }) => theme.secondaryBackground};
  color: ${({ theme }) => theme.text};
  appearance: none;
  background-image: ${({ theme }) =>
    `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='${encodeURIComponent(
      theme.reverseBackground,
    )}'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  cursor: pointer;

  /* Efeito de hover */
  &:hover {
    background: ${({ theme }) =>
      theme.primaryBackground}; /* Altere para a cor desejada */
    border-color: ${({ theme }) =>
      theme.primary}; /* Altere a borda, se necessário */
    color: ${({ theme }) =>
      theme.reverseText}; /* Altere a cor do texto, se necessário */
  }
`;

const SelectItem = styled.option`
  padding: 8px 12px;
  font-size: 10px;
  background: ${({ theme }) => theme.secondaryBackground};
  color: ${({ theme }) => theme.text};
  display: flex;
  align-items: center;

  /* Aqui garantimos que o conteúdo seja flexível */
  & svg {
    margin-right: 10px; /* Espaço entre o ícone e o texto */
  }
`;
const Range = styled.input`
  appearance: none;
  width: 100%;
  background: ${({ theme }) => theme.secondaryBackground};
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ theme }) => theme.text};
    cursor: pointer;
  }
`;

const Textarea = styled.textarea`
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.secondaryBackground};
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 18px;
  height: 18px;
`;

const Radio = styled.input.attrs({ type: "radio" })`
  width: 18px;
  height: 18px;
`;

const FileInput = styled.input.attrs({ type: "file" })`
  padding: 5px;
  font-size: 10px;
  color: ${({ theme }) => theme.text};
`;

interface FormComponent extends FC<FormProps> {
  Group: typeof InputGroup;
  Label: typeof Label;
  Input: typeof Input;
  TextArea: typeof Textarea;
  Select: typeof Select;
  SelectItem: typeof SelectItem;
  Range: typeof Range;
  Checkbox: typeof Checkbox;
  Radio: typeof Radio;
  FileInput: typeof FileInput;
}

interface FormProps extends React.HTMLAttributes<HTMLFormElement> {
  children: ReactNode;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

const Form: FormComponent = ({ children, ...props }: FormProps) => {
  return <StyledForm {...props}>{children}</StyledForm>;
};

Form.Group = InputGroup;
Form.Label = Label;
Form.Input = Input;
Form.TextArea = Textarea;
Form.Select = Select;
Form.SelectItem = SelectItem;
Form.Range = Range;
Form.Checkbox = Checkbox;
Form.Radio = Radio;
Form.FileInput = FileInput;

export default Form;
