import { AnanseApiService } from "../AnanseApiService";
import { CRUDService } from "../CRUDService";

export class CRUDApiService extends AnanseApiService
  implements CRUDService<any> {
  constructor(resource: string) {
    if (!resource) {
      throw new Error("Resource should be provided!");
    }

    super(resource);
  }

  get(id: number | string): Promise<any> {
    if (!id) {
      throw new Error("id should be provided");
    }

    return this.makeHttpRequest({
      url: `/${id}`
    });
  }

  create<T>(payload: T): Promise<T> {
    if (!payload) {
      throw new Error("payload should be provided");
    }

    return this.makeHttpRequest({
      method: "POST",
      data: payload
    });
  }

  update<T>(id: number | string, payload: T): Promise<T> {
    if (!id) {
      throw new Error("id should be provided");
    }

    if (!payload) {
      throw new Error("payload should be provided");
    }

    return this.makeHttpRequest({
      method: "PUT",
      url: `/${id}`,
      data: payload
    });
  }

  delete(id: number, hardDelete = false): Promise<any> {
    if (!id) {
      throw new Error("id should be provided");
    }

    return this.makeHttpRequest({
      method: "DELETE",
      url: `/${id}`,
      params: {
        hardDelete
      }
    });
  }

  activate(id: number): Promise<any> {
    if (!id) {
      throw new Error("id should be provided");
    }

    return this.makeHttpRequest({
      method: "PUT",
      url: `/activate/${id}`
    });
  }
}
