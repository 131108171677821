import { CampaignType } from "app/types";
import { CRUDApiService } from "./CRUDApiService";
import { CampaignService } from "../CampaignService";

export class CampaignApiServiceImpl extends CRUDApiService
  implements CampaignService {
  constructor() {
    super("campaigns");
  }

  async getCampaigns(): Promise<Array<CampaignType>> {
    const response = await this.makeHttpRequest({
      url: "/"
    });
    return response?.data;
  }

  setDefault(campaignId: number): Promise<void> {
    return this.makeHttpRequest({
      url: "/default",
      method: "PUT",
      data: {
        campaignId
      }
    });
  }
}
