import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { TransferList } from "components";
import React, { useState } from "react";

interface Props {
  userIds: string;
  setUserIds: (userIds: string) => void;
}

const DialogSelectUsers: React.FC<Props> = ({ userIds, setUserIds }) => {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState<string[]>([]);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  React.useEffect(() => {
    const result = userIds.split(";").filter((id: string) => id !== "");
    setUsers(result);
  }, [userIds]);

  const handleMultiSelect = (event: any[]) => {
    const newId = event.map(usuario => usuario.id).join(";");
    setUserIds(newId);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="success"
        onClick={onOpen}
        style={{ borderRadius: 4 }}
      >
        Selecionar Usuários {` - ${users.length} selecionado(s)`}
      </Button>
      <Dialog open={open} maxWidth="lg">
        <DialogTitle>Selecione os usuarios e tranfira</DialogTitle>
        <DialogContent style={{ padding: 8 }}>
          <TransferList
            value={userIds}
            endPoint="usuario"
            handleMultiSelect={handleMultiSelect}
            placeholder=""
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogSelectUsers;
