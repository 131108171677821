import React, { useState } from "react";
import {
  PreviewDiscrimination,
  PreviewRatings,
  PreviewChildIdentification
} from "./index";
import "./style/sliderStyles.scss";
import PreviewIdentification from "./PreviewIdentification";
import { QuizType } from "app/types";
import { QUIZ_TYPES_CONTANTS } from "app/types/quiz-constants";

type File = {
  fileId: number;
  fileOwnerId: number;
  fileOwnerType: number;
  name: string;
  path: string;
  tempUri: string;
  downloads: number | null;
  file: string | null;
};

export type Answer = {
  quizQuestionAnswerId: number;
  answerId: number;
  answer: {
    label: string;
    files: File[];
  };
};

export type QuestionType = {
  quizQuestionId: number;
  arrangeQuestionSequence: number;
  question: {
    quest: string;
    description: string;
  };
  answers: Answer[];
  files: File[];
};

type QuizInnerProps = {
  quizId: number;
  name: string;
  questions: QuestionType[];
  type: QuizType["type"];
};

type PreviewQuizProps = {
  quiz: QuizInnerProps;
  currentQuestionIndex: number;
  handleNextQuestionIndex: () => void;
};

const { ANAMNESE, CHILDHOOD_IDENTIFICATION, COGNITIVE } = QUIZ_TYPES_CONTANTS;
const excludedTypes = [ANAMNESE, COGNITIVE, ""] as const;

const PreViewQuiz: React.FC<PreviewQuizProps> = ({
  quiz,
  currentQuestionIndex,
  handleNextQuestionIndex
}) => {
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);
  const scaleFactor = 0.4;
  const questions = quiz.questions.sort(
    (a, b) => a.arrangeQuestionSequence - b.arrangeQuestionSequence
  );
  const currentQuestion = questions[currentQuestionIndex];

  const imageBackground =
    currentQuestion.files && currentQuestion.files.length > 0
      ? currentQuestion.files[0]?.tempUri
      : "";

  if (excludedTypes.includes(quiz.type as typeof excludedTypes[number])) {
    return null;
  }

  const handleSelectAnswer = (answerId: number) => {
    setSelectedAnswerId(answerId);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      handleNextQuestionIndex();
      setSelectedAnswerId(null);
    }
  };

  const componentQuestionsProps = {
    scaleFactor,
    currentQuestionIndex,
    currentQuestion,
    handleNextQuestion,
    answers: currentQuestion.answers,
    questionsLength: questions.length
  };

  const componentQuestions = {
    "1": (
      <PreviewIdentification
        {...componentQuestionsProps}
        selectedAnswerId={selectedAnswerId}
        handleSelectAnswer={handleSelectAnswer}
      />
    ),
    "3": (
      <PreviewDiscrimination
        {...componentQuestionsProps}
        selectedAnswerId={selectedAnswerId}
        handleSelectAnswer={handleSelectAnswer}
      />
    ),
    "5": <PreviewRatings {...componentQuestionsProps} />,
    [CHILDHOOD_IDENTIFICATION]: (
      <PreviewChildIdentification
        {...componentQuestionsProps}
        selectedAnswerId={selectedAnswerId}
        handleSelectAnswer={handleSelectAnswer}
        imageBackground={imageBackground}
      />
    )
  };

  return (
    <Quiz
      componentStyleWrapper={
        componentQuestions[quiz.type as keyof typeof componentQuestions]
      }
    />
  );
};

type QuizProps = {
  componentStyleWrapper: JSX.Element;
};

const Quiz: React.FC<QuizProps> = ({ componentStyleWrapper }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "90%"
      }}
    >
      {componentStyleWrapper}
    </div>
  );
};

export default PreViewQuiz;
