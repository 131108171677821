import { QuizType } from "app/types";
import { CRUDApiService } from "./CRUDApiService";
import { QuizService } from "../QuizService";

export class QuizApiServiceImpl extends CRUDApiService implements QuizService {
  constructor() {
    super("quiz");
  }

  getById(id: number): Promise<QuizType> {
    const baseUrl = `details/${id}`;
    return this.makeHttpRequest({ method: "GET", url: baseUrl });
  }
}
