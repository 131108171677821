import { FC, StrictMode, useState } from "react";

import useManageDashboardData from "./hooks/useManageDashboardData";
import { DashDSAView } from "./dsa-view";
import DashDsaProvider from "./context/DashdsaContext";
import { AnanseApiService } from "app/services";
import { DashboardApiServiceImpl } from "app/services/Ananse/impl/DashboardApiService";
import "./dash.scss";

const DashboardDsaWrapper = (): JSX.Element => {
  return (
    <DashDsaProvider>
      <Dashboard />
    </DashDsaProvider>
  );
};

const Dashboard: FC = () => {
  const api = new AnanseApiService();
  const dashboardApi = new DashboardApiServiceImpl();
  const model = useManageDashboardData({ api, dashboardApi });
  return (
    <StrictMode>
      <DashDSAView {...model} />
    </StrictMode>
  );
};

export default DashboardDsaWrapper;
