import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-width: 400px;
  margin: 16px auto;
`;

const CardTitle = styled.div`
  background-color: ${({ theme }) => theme.secondaryBackground};
  padding: 16px;
  font-size: 1.25rem;
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.border};
`;

const CardBody = styled.div`
  padding: 16px;
  font-size: 1rem;
  color: ${({ theme }) => theme.secondaryText};
`;

const CardActions = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.secondaryBackground};
  text-align: right;
  border-top: 1px solid ${({ theme }) => theme.border};

  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface CardTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface CardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface CardActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Card: React.FC<CardProps> & {
  Title: React.FC<CardTitleProps>;
  Body: React.FC<CardBodyProps>;
  Actions: React.FC<CardActionsProps>;
} = ({ children, ...props }) => {
  return <CardContainer {...props}>{children}</CardContainer>;
};

Card.Title = ({ children, ...props }: CardTitleProps) => (
  <CardTitle {...props}>{children}</CardTitle>
);
Card.Body = ({ children, ...props }: CardBodyProps) => (
  <CardBody {...props}>{children}</CardBody>
);
Card.Actions = ({ children, ...props }: CardActionsProps) => (
  <CardActions {...props}>{children}</CardActions>
);

export default Card;
