import { ChangeEvent } from "react";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";

import FrameModal from "../../component/FrameModal";
import ArrangeSequenceModal from "../ArrangeSequenceModal";
import { QuestionType, QuizType } from "app/types";

type UtilityBarProps = {
  searchTerm: string;
  sortedQuestions: Array<QuestionType>;
  quiz: QuizType;
  clearTerm: () => void;
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  translate: (key: string) => string;
  onAdd: () => void;
  onRefresh: () => void;
};

const UtilityBar = ({
  clearTerm,
  searchTerm,
  handleSearch,
  translate,
  onAdd,
  onRefresh,
  sortedQuestions,
  quiz,
}: UtilityBarProps) => {
  return (
    <Stack
      sx={{
        padding: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      <FrameModal quiz={quiz} />
      <ArrangeSequenceModal onSave={onRefresh} questions={sortedQuestions} />
      <IconButton
        title={translate("screens_quiz_labels_addQuestion")}
        onClick={onAdd}
      >
        <Add />
      </IconButton>
      <TextField
        label={translate("labels_search")}
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        size="small"
        sx={{ marginLeft: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={clearTerm}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default UtilityBar;
