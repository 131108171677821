import { useTranslation } from "_metronic/i18n/language";
import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services";
import { CognitiveAnswerDetail } from "interfaces";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, Form } from "../../components";
import Paragraph from "../../components/Paragraph";
import { Typography } from "@mui/material";
import Swal from "sweetalert2";

const schema = yup.object().shape({
  idCognitive: yup
    .number()
    .typeError("O ID deve ser um número válido.")
    .required("Id da pergunta do teste Cognitivo é obrigatório."),
});

const RapairAnswerCognitive = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const translate = useTranslation();
  const api = new AnanseApiService();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ idCognitive: number }>({
    resolver: yupResolver(schema),
    defaultValues: {
      idCognitive: undefined,
    },
  });

  const helperText = (text: string) => (
    <Typography color={"red"}>{text}</Typography>
  );

  const showMensage = (sucess: any, error: any) => {
    if (error) {
      Swal.fire(
        translate("screenApp_resetFirmwareComponents_titleErro"),
        error ? error : translate("labels_errorEmail"),
        "error",
      );
    } else if (sucess) {
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: translate(sucess),
        // text: translate("labels.emailSuccess"),
        icon: "success",
        showCancelButton: false,
        confirmButtonText: translate("buttons.ok"),
        cancelButtonText: translate("buttons.exit"),
      });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      if (data.idCognitive) {
        const reqBase = {
          method: "GET",
          url: `/quizanswercognitive/${data.idCognitive}`,
        };

        const cognitiveAnswerDetail: CognitiveAnswerDetail = await api.makeHttpRequest(
          {
            ...reqBase,
          },
        );

        if (cognitiveAnswerDetail) {
          history.push(`/admin/cognitive/update`, { cognitiveAnswerDetail });
        }
      } else {
        showMensage(false, "O ID deve ser um número válido.");
      }
    } catch (error) {
      if ((error as any).response.data) {
        showMensage(false, (error as any).response.data);
      } else {
        showMensage(false, "Falha de comunicação com a Base de Dados."); // translate("")
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card
          style={{
            width: "95%",
          }}
        >
          <Card.Title>
            {/* {translate("")} */}
            Digite o id da resposta
          </Card.Title>
          <Card.Body
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Box
              style={{
                padding: "20px 10px",
              }}
            >
              <Form.Group
                style={{
                  width: "100%",
                }}
              >
                <Form.Label>
                  <Paragraph>
                    <Paragraph.Bold>
                      {/* {translate("")} */}
                      ID da reposta:
                    </Paragraph.Bold>
                  </Paragraph>
                </Form.Label>
                <Form.Input
                  style={{ maxWidth: "500px" }}
                  {...register("idCognitive", { required: true })}
                  value={watch("idCognitive") || ""}
                />
                {errors.idCognitive &&
                  helperText(errors.idCognitive?.message || "")}
              </Form.Group>
            </Box>
          </Card.Body>
          <Card.Actions
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "end",
              gap: "15px",
            }}
          >
            <Link to={"/admin/home"}>
              <Button type="button" variant="error">
                {translate("buttons_back")}
              </Button>
            </Link>
            <Button type="submit" variant="success">
              {translate("buttons_send")}
            </Button>
          </Card.Actions>
        </Card>
      </Form>
    </div>
  );
};

export default RapairAnswerCognitive;
