import { Link } from "react-router-dom";
import { Button, Card, CardActions, CardHeader, Stack } from "@mui/material";

import CardContentView from "./CardContentView";
import useQuizFormModel from "../hooks/useQuizFormNewModel";

type CardViewProps = Pick<
  ReturnType<typeof useQuizFormModel>,
  | "translate"
  | "id"
  | "errors"
  | "userIds"
  | "styleInput"
  | "register"
  | "watch"
  | "selectedType"
  | "selectTheTypeQuiz"
  | "handleChangeType"
  | "language"
  | "setLanguage"
  | "campaigns"
  | "control"
> & {
  setUserIds: (userIds: string) => void;
};

const CardView = ({
  translate,
  id,
  errors,
  userIds,
  setUserIds,
  styleInput,
  register,
  watch,
  selectedType,
  selectTheTypeQuiz,
  handleChangeType,
  language,
  setLanguage,
  campaigns,
  control
}: CardViewProps) => {
  return (
    <Card style={{ marginBottom: 5 }}>
      <CardHeader title={translate("screens.quiz.labels.principal")} />
      <CardContentView
        {...{
          errors,
          userIds,
          setUserIds,
          styleInput,
          register,
          watch,
          selectedType,
          selectTheTypeQuiz,
          handleChangeType,
          language,
          setLanguage,
          campaigns,
          control,
          translate
        }}
      />
      <CardActions style={{ flex: 1, justifyContent: "flex-end" }}>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Link to={"/admin/quiz"}>
            <Button type="button" color="error" variant="contained">
              {translate("buttons_cancel")}
            </Button>
          </Link>
          <Button type="reset" color="warning" variant="contained">
            {translate("buttons_clean")}
          </Button>
          <Button type="submit" color="success" variant="contained">
            {translate(id ? "buttons_edit" : "buttons_save")}
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default CardView;
