import { QUIZ_TYPES_CONTANTS } from "../../../../types/quiz-constants";

const {
  NONE,
  IDENTIFICATION,
  ANAMNESE,
  DISCRIMINATION,
  CHILDHOOD_IDENTIFICATION_HC,
  RATINGS,
  CHILDHOOD_IDENTIFICATION,
  COGNITIVE,
} = QUIZ_TYPES_CONTANTS;

export const hideInputs = {
  maxShots: [COGNITIVE],
  anamnese: [] as Array<number>,
  screening: [
    NONE,
    IDENTIFICATION,
    ANAMNESE,
    DISCRIMINATION,
    CHILDHOOD_IDENTIFICATION_HC,
    RATINGS,
    CHILDHOOD_IDENTIFICATION,
  ]
};
