import { FormEventHandler } from "react";
import { Button, DialogActions, Divider, Stack } from "@mui/material";
import { Form } from "react-bootstrap";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";

import { SelectInputType } from "mocks/quizInputs";
import {
  AnswerType,
  CognitiveCategoryType,
  CollectionType,
  QuestionType,
  QuizType,
} from "app/types";
import DialogContentView from "./DialogContentView";
import { QuestionFormType } from "../update-type";

type DialogFormViewProps = {
  errors: FieldErrors<QuestionType>;
  answers: Array<AnswerType>;
  styleInput: {
    backgroundColor: string;
    color: string;
  };
  control: Control<QuestionFormType, any>;
  selectedType: SelectInputType;
  collections: Array<CollectionType>;
  fields: FieldArrayWithId<QuestionType, "answers", "id">[];
  quiz: QuizType;
  preViewImage: string;
  cognitiveInputs: Array<{ type: string; name: string }>;
  cognitiveCategories: Array<CognitiveCategoryType>;
  translate: (key: string) => string;
  clearImageQuestion: () => void;
  onClose: () => void;
  showModalAnswersImage: () => void;
  append: UseFieldArrayAppend<QuestionFormType, "answers">;
  remove: UseFieldArrayRemove;
  handleDescChange: (index: number, value: string) => void;
  register: UseFormRegister<QuestionFormType>;
  handleFileQuestion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearQuest: () => void;
  handleQuestChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined;
};

const DialogFormView = ({
  translate,
  clearImageQuestion,
  onClose,
  showModalAnswersImage,
  errors,
  answers,
  styleInput,
  control,
  append,
  selectedType,
  collections,
  remove,
  handleDescChange,
  register,
  fields,
  quiz,
  handleFileQuestion,
  preViewImage,
  cognitiveInputs,
  handleClearQuest,
  handleQuestChange,
  onSubmit,
  cognitiveCategories,
}: DialogFormViewProps) => {
  return (
    <Form onSubmit={onSubmit}>
      <DialogContentView
        answers={answers}
        append={append}
        cognitiveCategories={cognitiveCategories}
        cognitiveInputs={cognitiveInputs}
        collections={collections}
        control={control}
        errors={errors}
        fields={fields}
        handleClearQuest={handleClearQuest}
        handleDescChange={handleDescChange}
        handleFileQuestion={handleFileQuestion}
        handleQuestChange={handleQuestChange}
        preViewImage={preViewImage}
        quiz={quiz}
        register={register}
        remove={remove}
        selectedType={selectedType}
        showModalAnswersImage={showModalAnswersImage}
        styleInput={styleInput}
        translate={translate}
      />
      <Divider style={{ backgroundColor: "#757575" }} />
      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="contained" color="error" onClick={onClose}>
            {translate("buttons_cancel")}
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={clearImageQuestion}
          >
            {translate("buttons_clean")}
          </Button>
          <Button type="submit" variant="contained" color="success">
            {translate("buttons_send")}
          </Button>
        </Stack>
      </DialogActions>
    </Form>
  );
};

export default DialogFormView;
