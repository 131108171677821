import { Form } from "react-bootstrap";
import useQuizFormModel from "../hooks/useQuizFormNewModel";
import CardView from "./CardView";

type QuizFormProps = Omit<
  ReturnType<typeof useQuizFormModel>,
  | "loading"
  | "quizData"
  | "getQuizById"
  | "onCleanQuestion"
  | "selectedQuestion"
  | "openQuestionModal"
  | "setOpenQuestionModal"
  | "questions"
  | "onDelete"
  | "onEdit"
  | "setUserIds"
> & {
  setUserIds: (userIds: string) => void;
};

const QuizFormView = ({
  onSubmitForm,
  translate,
  id,
  errors,
  userIds,
  setUserIds,
  styleInput,
  register,
  watch,
  selectedType,
  selectTheTypeQuiz,
  handleChangeType,
  language,
  setLanguage,
  campaigns,
  control
}: QuizFormProps) => {
  return (
    <Form onSubmit={onSubmitForm}>
      <CardView
        campaigns={campaigns}
        control={control}
        errors={errors}
        handleChangeType={handleChangeType}
        id={id}
        language={language}
        register={register}
        selectTheTypeQuiz={selectTheTypeQuiz}
        selectedType={selectedType}
        setLanguage={setLanguage}
        setUserIds={setUserIds}
        styleInput={styleInput}
        translate={translate}
        userIds={userIds}
        watch={watch}
      />
    </Form>
  );
};

export default QuizFormView;
