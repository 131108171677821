import { FileType } from "app/types";

export const defaultValues = {
  quizId: 0,
  quizQuestionId: 0,
  questionId: 0,
  quest: "",
  description: "",
  fragranceId: "",
  rightAnswer: "",
  type: "",
  file: {} as FileType,
  answers: [
    {
      quizQuestionAnswerId: 0,
      answerId: 0,
      desc: "",
      description: "",
      nextQuizQuestionId: 0
    }
  ]
};
