import React, { StrictMode } from "react";
import { useDashDsaResultModel } from "./hooks/useDashDsaResultModel";
import { DashDsaResultView } from "./dsaresult-view";
import { AnanseApiService } from "app/services";
import { DashboardApiServiceImpl } from "app/services/Ananse/impl/DashboardApiService";
import useManageDashboardData from "../dsa/hooks/useManageDashboardData";

const DashboardResultados: React.FC = () => {
  const api = new AnanseApiService();
  const dashboardApi = new DashboardApiServiceImpl();
  const model = useDashDsaResultModel({ api, dashboardApi });
  const { getUniqueTestNames } = useManageDashboardData({ api, dashboardApi });

  return (
    <StrictMode>
      <DashDsaResultView {...model} getUniqueTestNames={getUniqueTestNames} />
    </StrictMode>
  );
};

export default DashboardResultados;
