import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "success" | "error";
  mode?: "container" | "outline" | "text";
  children: React.ReactNode;
}

const StyledButton = styled.button<ButtonProps>`
  padding: 10px 20px;
  font-size: 1.2em !important;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  border: ${({ mode, theme, variant }) =>
    mode === "outline"
      ? `2px solid ${theme[variant || "button"]}`
      : "none"} !important;
  background-color: ${({ mode, theme, variant }) =>
    mode === "container"
      ? theme[variant || "button"]
      : "transparent"} !important;
  color: ${({ mode, theme, variant }) =>
    mode === "container" ? "#fff" : theme[variant || "button"]} !important;

  &:hover {
    background-color: ${({ mode, theme, variant }) =>
      mode === "container"
        ? theme.secondaryBackground
        : theme[variant || "button"]} !important;
    color: ${({ mode }) => (mode === "container" ? "#fff" : "#000")} !important;
  }
`;

import React from "react";

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
