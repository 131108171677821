import { CollectionType } from "app/types";

const useQuestionsListModel = () => {
  const getFragranceById = (
    collections: Array<CollectionType>,
    fragranceId: number
  ): string => {
    if (!collections.length) {
      return fragranceId.toString();
    }
    const fragrance = collections.find(
      collection => collection.fragranceId == fragranceId
    );
    return fragrance?.fragrance.name || "N/A";
  };

  return { getFragranceById };
};

export default useQuestionsListModel;
