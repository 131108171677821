import { useTranslation } from "_metronic/i18n/language";
import Swal from "sweetalert2";

const useQuizMessages = () => {
  const translate = useTranslation();

  const getSuccessCreated = (): void => {
    Swal.fire({
      title: translate("defaultMessages_success"),
      text: "Teste criado com sucesso!",
      icon: "success"
    });
  };

  const getSuccessDeleted = (): void => {
    Swal.fire(
      translate("defaultMessages_success"),
      translate("deleteRegister_deletedSuccess"),
      "success"
    );
  };

  const getErrorCreated = (): void => {
    Swal.fire({
      title: "Erro",
      text: "Tente novamente",
      icon: "error"
    });
  };

  const getErrorDeleted = (): void => {
    Swal.fire(
      translate("dashboard_error"),
      translate("deleteRegister_deletedError"),
      "error"
    );
  };

  return {
    getSuccessCreated,
    getErrorCreated,
    getSuccessDeleted,
    getErrorDeleted
  };
};

export default useQuizMessages;
