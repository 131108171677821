export enum QUIZ_TYPES_CONTANTS {
  NONE = 0,
  IDENTIFICATION = 1,
  ANAMNESE = 2,
  DISCRIMINATION = 3,
  CHILDHOOD_IDENTIFICATION_HC = 4,
  RATINGS = 5,
  CHILDHOOD_IDENTIFICATION = 6,
  COGNITIVE = 7
}
